import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {Alert, Box, TextField} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {ISnackbarSlice, openSnackbar} from '../../redux/features/snackbar';
import {patchProgram} from "../../requests/program.request";
import SearchMajorTextField from "../SearchTextField/SearchMajorTextField";
import {
    SearchDisciplineResponseType,
    SearchLevelResponseType,
    SearchMajorResponseType,
    SearchSchoolResponseType
} from "../../types";
import SearchLevelTextField from "../SearchTextField/SearchLevelTextField";
import SearchDisciplineTextField from "../SearchTextField/SearchDisciplineTextField";
import {RootState, useAppSelector} from "../../redux/store";
import {setDisciplineTemp} from "../../redux/features/school";
import {useAddEntities} from "../../hooks/useAddEntities";
import {setErrorSnackbar} from "../../helper/utils/helper";
import {setEditProgramId} from "../../redux/features/program";
import {LoadingButton} from "@mui/lab";
import {useQueryClient} from "react-query";

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    majorId: yup.number().nullable(),
    majorName: yup.string().notRequired().nullable(),
    programId: yup.number(),
    disciplineId: yup.number().notRequired(),
    levelId: yup.number().notRequired(),
    programSchoolId: yup.number().notRequired(),
    levelName: yup.string().notRequired(),
    disciplineName: yup.string().notRequired(),
})

const initialValues = {
    schoolId: "",
    name: '',
    majorName: '',
    programSchoolId: 0,
    majorId: 0,
    programCode: "",
    levelName: "",
    levelId: 0,
    disciplineName: "",
    disciplineId: 0
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IProps {
    open: boolean
    handleClose: () => void
    setting?: boolean
}

const EditProgram = React.memo((props: IProps) => {
    const {open, handleClose, setting} = props
    const dispatch = useDispatch()
    const {schoolId, regionId} = useAddEntities();
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const programId = useAppSelector((store) => store?.program?.programId);
    const levelId = useAppSelector((store) => store?.program?.levelId);
    const name = useAppSelector((store) => store?.program?.name);
    const levelName = useAppSelector((store) => store?.program?.levelName);
    const majorId = useAppSelector((store) => store?.program?.majorId);
    const programSchoolId = useAppSelector((store) => store?.program?.programSchoolId);
    const majorName = useAppSelector((store) => store?.program?.majorName);
    const disciplineId = useAppSelector((store) => store?.program?.disciplineId);
    const disciplineName = useAppSelector((store) => store?.program?.disciplineName);
    const [mergeMajor, setMergeMajor] = useState({id: 0, name: ""})
    const [mergeLevel, setMergeLevel] = useState({id: 0, name: ""})
    const [mergeDiscipline, setMergeDiscipline] = useState({id: 0, name: ""})



    const queryClient = useQueryClient();
    const [school, setSchool] = useState<any>()

    const [isLoading, setIsLoading] = useState(false)
    const schoolIdSetting = useAppSelector((store) => store?.school.schoolIdSetting);
    const [schoolById, setSchoolById] = useState()
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                setIsLoading(true)
                if(!values?.levelId){
                    setIsLoading(false)
                    setSnackbarStore({
                        message: "Level is required",
                        severity: "error",
                        open: true
                    })
                    return
                }

                if(!(values?.disciplineId || disciplineId)){
                    setIsLoading(false)
                    setSnackbarStore({
                        message: "Discipline is required",
                        severity: "error",
                        open: true
                    })
                    return
                }
                if (!setting) {
                    values.schoolId = schoolId
                } else if (setting) {
                    values.schoolId = schoolId ? schoolId : school?.id ?? schoolIdSetting
                }
                if(programSchoolId){
                    values.programSchoolId = programSchoolId
                }

                var res = await patchProgram(values, programId)
                console.log(res, "res")



                queryClient.setQueriesData('program', (oldData: any) => {
                    return {
                        ...oldData, data: oldData?.data?.map((program: any) => {

                            if (program.id === programId) {

                                program.major = {
                                    id: values.majorId,
                                    name: values.majorName
                                }
                                program.level = {
                                    id: values.levelId,
                                    name: values.levelName
                                }
                                return program
                            }
                            return program
                        })
                    }
                })

                await queryClient.invalidateQueries("program")
                //  dispatch(setProgramTabStateCount())
                actions.resetForm()
                /*dispatch(setTabValue("curriculum"))
                dispatch(setCurriculumTabState(true))*/
                if (res.message) {
                    var __message = values?.name + " already exists in our system. Please try a different one."

                    setSnackbarStore({
                        message: res.message,
                        severity: __message == res.message ? "error" : "success",
                        open: true,
                    });

                } else {
                    setSnackbarStore({
                        message: "Successfully added program",
                        severity: "success",
                        open: true,
                    })
                }
                if(res?.succeded == true){
                    dispatch(setEditProgramId(0))
                }

                setIsLoading(false)
                handleClose()
            } catch (error: any) {
                setIsLoading(false)
                setErrorSnackbar(error, setSnackbarStore)
            }
        }
    })

    useEffect(() => {
        formik.setFieldValue('levelId', levelId)
        formik.setFieldValue('name', name)
        formik.setFieldValue('levelName', levelName)
        formik.setFieldValue('majorId', majorId)
        formik.setFieldValue('majorName', majorName)
        formik.setFieldValue('programSchoolId', programSchoolId)
        formik.setFieldValue('disciplineId', disciplineId)
        formik.setFieldValue('disciplineName', disciplineName)
        setMergeDiscipline({
            id: disciplineId  ?? 0,
            name: disciplineName
        })
        setMergeMajor({
            id: majorId ?? 0,
            name: majorName ?? ""
        })
        setMergeLevel({
            id: levelId  ?? 0,
            name: levelName?? ""
        })

    }, [programSchoolId, programId, levelId, name, levelName, majorId, majorName, disciplineId, disciplineName])
    const handleSubmit = React.useCallback(() => {
        formik.handleSubmit()
    }, [formik])

    const handleOnSelectedMajor = useCallback((value: SearchMajorResponseType) => {

        const majorId = Number(value?.id);
        if (!isNaN(majorId)) {
            formik.setFieldValue('majorId', majorId, true);
            formik.setFieldValue('majorName', value?.name ?? "", true);
        } else {
            console.error('Selected major ID is not a number:', value?.id);
            formik.setFieldValue('majorId', null, true);
            formik.setFieldValue('majorName', "", true);
        }
    }, [formik])
    const handleOnSelectedDiscipline = useCallback((value: SearchDisciplineResponseType) => {
        dispatch(setDisciplineTemp(value))
        formik.setFieldValue('disciplineId', value?.id ?? "")
        formik.setFieldValue('disciplineName', value?.name ?? "")
    }, [formik])

    const handleOnSelectedLevel = useCallback((value: SearchLevelResponseType) => {

        formik.setFieldValue('levelId', value?.id ?? "")
        formik.setFieldValue('levelName', value?.name ?? "")
    }, [formik])
    const authStore = useSelector((store: RootState) => store.auth);
    return (
        <Dialog

            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Edit Program <p style={{margin: 0,fontSize: 12, fontStyle: "italic"}}>({authStore?.user?.school?.name ?? ""})</p></DialogTitle>

            <DialogContent>
                <Alert style={{marginBottom: 12}} severity="info">
                    Please fill in the details of the program you want to edit
                    <br />
                    Note: Fields with <strong>*</strong> are required
                </Alert>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 3,
                    }}
                >

                    <>
                        {setting && mergeDiscipline?.id ?
                            <SearchDisciplineTextField
                                disabled={true}
                                defaultValue={mergeDiscipline}
                                schoolId={formik.values.schoolId ?? schoolId}
                                label='Discipline'
                                onSelected={handleOnSelectedDiscipline}
                            />
                            : mergeDiscipline?.id ? <SearchDisciplineTextField
                                defaultValue={mergeDiscipline}
                                disabled={true}
                                schoolId={schoolId}
                                label='Discipline'
                                onSelected={handleOnSelectedDiscipline}
                            /> : <SearchDisciplineTextField
                                schoolId={schoolId}
                                disabled={true}
                                label='Discipline'
                                onSelected={handleOnSelectedDiscipline}
                            />}

                        {formik.values.name ?
                            <TextField
                                label="Name"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                required
                                id='name'
                                name='name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            /> : <TextField
                                label="Name"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                required
                                id='name'
                                name='name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        }

                        {/* {setting ? <Box paddingBottom={1}>
                            {<SearchAsYouType
                                value={schoolById || ""}
                                id={"SchoolInstitutionalNameselect"}
                                disabled={schoolById || false}
                                required={true}
                                error={onErrorSelectedSchool}
                                label='School Institutional Name'
                                onSelected={handleOnSelectedSchool}
                            />}
                        </Box> : null

                        }*/}
                        {mergeLevel?.id ?
                            <SearchLevelTextField
                                defaultValue={mergeLevel}
                                label='Level'
                                onSelected={handleOnSelectedLevel}
                            /> : <SearchLevelTextField
                                label='Level'
                                onSelected={handleOnSelectedLevel}
                            />
                        }
                        {mergeMajor?.id ?
                            <SearchMajorTextField
                                defaultValue={mergeMajor}
                                label='Major'
                                onSelected={handleOnSelectedMajor}
                            /> : <SearchMajorTextField
                                label='Major'
                                onSelected={handleOnSelectedMajor}
                            />
                        }
                    </>


                </Box>
            </DialogContent>
            <DialogActions>
                <Button id={"cancelProgram"} onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={isLoading} disabled={!(formik.isValid && formik.dirty)} id={"editProgram"}
                               variant='contained'
                               onClick={handleSubmit}>Update</LoadingButton>
            </DialogActions>
        </Dialog>
    )
})

export default EditProgram
