import React, {useEffect} from "react";
import {Redirect, Route, RouteProps} from 'react-router-dom'
import {routes} from '../config'
import {Layout} from './Layout'
import {PageDefault} from './PageDefault';
import {Route as AppRoute} from '../types';
import {useSelector} from 'react-redux';
import type {RootState} from '../redux/store';
import AccountSetting from '../pages/AccountSetting.page';
import WorkspaceIdPage from "../pages/WorkspaceId.page";
import CreateWorkspaceModal from "./Workspace/create-workspace-modal";
import useLogSignalr from "../hooks/useLogSignalr";
import MemberIdPage from "./Workspace/member/[memberId]/page";
import VideoCall from "../pages/WorkspaceVideo.page";
function Private() {

    const addRoute = (route: AppRoute) => (
        <PrivateRoute

            key={route.key}
            path={route.path}
            component={route.component || PageDefault}
            exact
        />
    );


    const {
        initAppSignalR,
        onAddLog,
        onProgress,
        OnInit,
        CallInitiate,
        onAppConnection,
        FileGenerationComplete,
        destroyAppSignalR,
        connectionStatus
    } = useLogSignalr();




    const authStore = useSelector((state: RootState) => state.auth)

    useEffect(() => {

        const init = async () => {
            try {
                if (connectionStatus === "disconnected" && authStore.isAuth) {
                    await initAppSignalR(authStore?.token);

                    onAppConnection('FileGenerationComplete', FileGenerationComplete);
                    onAppConnection('OnInit', OnInit);
                    onAppConnection('OnProgress', onProgress);
                    onAppConnection('CallInitiate', CallInitiate);
                }

            } catch (error) {
                console.log ('Error initializing SignalR:', error);
            }
        };
        if(authStore.isAuth){
            init();
        }
        return () => {
            (async () => {
                try {

                    if (connectionStatus === "connected" && authStore.isAuth) {
                        await destroyAppSignalR();
                    }
                } catch (error) {
                    console.error('Error destroying SignalR:', error);
                }
            })();
        };
    }, [initAppSignalR, onAppConnection,  FileGenerationComplete, onProgress, OnInit, destroyAppSignalR, connectionStatus, authStore?.token, authStore.isAuth]);


    return (
        <>
            <CreateWorkspaceModal></CreateWorkspaceModal>
            <PrivateRoute path="/videocall" component={VideoCall} exact/>
            <PrivateRoute path="/workspace/:workspaceId/member/:memberId" component={MemberIdPage} exact/>
            <PrivateRoute path="/workspace/:workspaceId/channel/:channelId" component={WorkspaceIdPage} exact/>
            <PrivateRoute path="/workspace/:workspaceId" component={WorkspaceIdPage} exact/>
            <PrivateRoute path='/account-settings' component={AccountSetting} exact/>
            {routes.map((route: AppRoute) => {
                // if (route.roles?.includes(authState.user?.roles?.toLocaleLowerCase() ?? 'user')) {
                return route.subRoutes ? route.subRoutes.map((item: AppRoute) => addRoute(item)) : addRoute(route)
                // }
            }
            )}
        </>
    )
}

export default Private

export const PrivateRoute = ({ component: Component, ...rest }: { component: any } & RouteProps) => {

    const authStore = useSelector((state: RootState) => state.auth)
    return (
        <Route   {...rest} render={(props) => (
            authStore.isAuth
                ? <Layout  >

                        <Component   {...props} />
                </Layout>
                : <Redirect to='/login' />
        )} />
    )
}