import {AccountCircleRounded, HomeRounded} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Grid,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import moment from 'moment-timezone';
import React, {useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import {AvatarCustomBadge} from "../components/Avatar";
import TabPanel from "../components/TabPanel";
import {useAppSelector} from "../redux/store";
import AddEditProfile from "../components/dialogs/AddEditProfile.dialog";
import useDialog from "../hooks/useDialog";
import UserTable from "../components/Tables/UserTable";
import AcademicCalendarTable from "../components/Tables/AcademicCalendarTable";
import ChangePassword from "../components/dialogs/ChangePassword.dialog";
import UpdateSchoolDialog from "../components/dialogs/UpdateSchool.dialog";
import {InstitutionalProfile} from "./InstutionalProfile.page";
import {SpecialOrderPricingRuleTable} from "../components/Tables/SpecialOrderPricingRuleTable";
import {RequirementsTable} from "../components/Tables/RequirementsTable";
import {useSchoolIdQuery} from "../hooks/useSchoolIdQuery";

function Account(props: { user: any }) {
  const { user } = props;
  return <div className="bg-white border border-gray-200 shadow-md rounded-lg p-6">
    <div className="text-gray-700 mb-6">
      <h2 className="text-xl font-semibold">Account Information</h2>
      <p className="text-sm text-gray-500">Manage your account settings and details below.</p>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Left Column */}
      <div className="space-y-4">
        <div>
          <Typography variant="subtitle2" className="text-sm font-medium text-gray-600">
            Username
          </Typography>
          <Chip
              variant="filled"
              label={user?.userName ?? "-"}
              className="mt-1 bg-blue-50 text-blue-600 font-medium"
          />
        </div>
        <div>
          <Typography variant="subtitle2" className="text-sm font-medium text-gray-600">
            First Name
          </Typography>
          <Chip
              variant="filled"
              label={user?.userProfile?.firstName ?? "-"}
              className="mt-1 bg-blue-50 text-blue-600 font-medium"
          />
        </div>
        {user?.type?.name?.trim() && (
            <div>
              <Typography variant="subtitle2" className="text-sm font-medium text-gray-600">
                Type
              </Typography>
              <Chip
                  variant="filled"
                  label={user?.type?.name?.trim()}
                  className="mt-1 bg-green-50 text-green-600 font-medium"
              />
            </div>
        )}
        <div>
          <Typography variant="subtitle2" className="text-sm font-medium text-gray-600">
            Designation
          </Typography>
          <Chip
              variant="filled"
              label={user?.userProfile?.designation?.trim() || "-"}
              className="mt-1 bg-blue-50 text-blue-600 font-medium"
          />
        </div>
        <div>
          <Typography variant="subtitle2" className="text-sm font-medium text-gray-600">
            Region
          </Typography>
          <Chip
              variant="filled"
              label={user?.region?.name ?? "-"}
              className="mt-1 bg-purple-50 text-purple-600 font-medium"
          />
        </div>
      </div>

      {/* Right Column */}
      <div className="space-y-4">
        <div>
          <Typography variant="subtitle2" className="text-sm font-medium text-gray-600">
            Email
          </Typography>
          <Chip
              variant="filled"
              label={user?.email ?? "-"}
              className="mt-1 bg-blue-50 text-blue-600 font-medium"
          />
        </div>
        <div>
          <Typography variant="subtitle2" className="text-sm font-medium text-gray-600">
            Last Name
          </Typography>
          <Chip
              variant="filled"
              label={user?.userProfile?.lastName ?? "-"}
              className="mt-1 bg-blue-50 text-blue-600 font-medium"
          />
        </div>
      </div>
    </div>
  </div>;
}


const AccountSetting = () => {
  const history = useHistory();
  const {user} = useAppSelector((store) => store.auth);
  const [tabValue, setTabValue] = useState(0);
  const _school = useAppSelector((store) => store?.auth?.user?.school);

  const pushTo = useCallback(
      (path: string) => {
        history.push(path);
      },
      [history]
  );
  const {data: school, isLoading} = useSchoolIdQuery(
      _school?.id
  );
  const breadcrumbs = [
    <Link
        underline="hover"
        key="1"
        color="inherit"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => pushTo("/")}
    >
      <HomeRounded sx={{mr: 1}} fontSize="small"/>
      Home
    </Link>,
    <Typography
        key="3"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
    >
      <AccountCircleRounded sx={{mr: 1}} fontSize="small"/>
      Account
    </Typography>,
  ];
  const {
    handleOpen: handleOpenAddUser,
    handleClose: handleCloseAddUser,
    open: openAddUser,
  } = useDialog();
  const {
    handleOpen: handleOpenEditInstitutionalProfile,
    handleClose: handleCloseEditInstitutionalProfile,
    open: openInstitutionalProfile,
  } = useDialog();

  const {
    handleOpen: handleOpenChangePassword,
    handleClose: handleCloseChangePassword,
    open: openChangePassword,
  } = useDialog();


  return (
      <>
        <Box
            component="main"
            sx={{flexGrow: 1, pt: 10}}
            style={{paddingLeft: " 20px", paddingRight: "20px"}}
        >
          <Container>
            <Paper
                sx={{
                  p: 2,
                  mb: 2,
                }}
            >
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </Paper>

            <Paper
                sx={{
                  p: 2,
                }}
            >
              <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
              >
                <Tabs variant="scrollable"
                      scrollButtons="auto"
                      value={tabValue}
                      onChange={(e, value) => setTabValue(value)}
                      sx={{
                        mb: 2,
                      }}
                >
                  <Tab id="Account" label="Account" value={0}/>

                  {(["Admin", "User"].includes(user.roles) && user?.type?.id != 2 && user?.type?.id != 3) && (
                      <Tab id={"InstitutionProfile"} label="Institution Profile" value={1}/>
                  )}

                  <Tab id="Security" label="Security" value={2}/>
                  {(["Admin", "SuperAdmin"].includes(user.roles) || user?.type?.id == 2 || user?.type?.id == 3) && (
                      <Tab label="Users" value={3}/>
                  )}

                  {(["Admin"].includes(user.roles) && user?.type?.id != 2 && user?.type?.id != 3) && (
                      <Tab id={"AcademicCalendar"} label="Academic Calendar" value={4}/>
                  )}
                  {(["Admin"].includes(user.roles) && user?.type?.id == 2) && (
                      <Tab label="Pricing Rules" value={6}/>
                  )}
                  {(["Admin"].includes(user.roles) && user?.type?.id == 2) && (
                      <Tab label="Requirements" value={7}/>
                  )}
              </Tabs>

              {tabValue === 0 && (
                  <Button id={"editButton"} onClick={handleOpenAddUser} variant="contained">
                    Edit
                  </Button>
              )}
              {tabValue === 2 && (
                  <Button id={"changePassword"} onClick={handleOpenChangePassword} variant="contained">
                    Change Password
                  </Button>
              )}

              {tabValue === 1 && !(user.type?.id == 1 && user?.roles?.toLowerCase() == "user") && (
                  <Button id={"EditInstitutionalProfile"}
                          onClick={handleOpenEditInstitutionalProfile}
                          variant="contained"
                  >
                    Edit Institutional Profile
                  </Button>
              )}
            </Box>
            <Grid container gap={2}>
              {[0, 2].includes(tabValue) && (
                  <Grid justifyContent={"space-between"} item>
                    <AvatarCustomBadge
                        sx={{width: 200, height: 200}}
                        src={""}
                        alt={user?.userName ?? ""}
                        badgeSrc="/assets/Verified_Badge.png"
                        badgeAlt={""}
                        variant="rounded"
                    />
                  </Grid>
              )}
              <Grid item>
                <TabPanel value={tabValue} index={0}>
                  <Account user={user}/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <InstitutionalProfile school={user?.school}/>
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                  <div className="space-y-6 p-6 bg-gray-50 rounded-lg shadow-lg max-w-md mx-auto">
                    {/* Password */}
                    <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition duration-150">
                      <div>
                        <h3 className="text-sm font-semibold text-gray-600">Password</h3>
                        <p className="mt-1 text-lg font-semibold text-gray-800 tracking-widest">********</p>
                      </div>
                    </div>

                    {/* Confirm Password */}
                    <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition duration-150">
                      <div>
                        <h3 className="text-sm font-semibold text-gray-600">Confirm Password</h3>
                        <p className="mt-1 text-lg font-semibold text-gray-800 tracking-widest">********</p>
                      </div>

                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                  <Stack gap={1}>
                    <Box>
                      <Typography variant="subtitle1">Last Login</Typography>
                      <Chip
                          variant="filled"
                          label={moment("2021-07-20 12:00:00").fromNow()}
                      ></Chip>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1">Last Logout</Typography>
                      <Chip
                          variant="filled"
                          label={moment("2021-07-20 12:00:00").fromNow()}
                      ></Chip>
                    </Box>
                  </Stack>
                </TabPanel>
              </Grid>
            </Grid>

            {(["Admin", "SuperAdmin"].includes(user.roles) || user?.type?.id == 2 || user?.type?.id == 3) && (
                <TabPanel value={tabValue} index={3}>
                  <UserTable title="Users"/>
                </TabPanel>
            )}

            {user.roles == "Admin" ? (
                <TabPanel value={tabValue} index={4}>
                    <AcademicCalendarTable title="Academic Calendar"/>
                </TabPanel>
            ) : null}

              {(["Admin", "SuperAdmin"].includes(user.roles) || user?.type?.id == 2 || user?.type?.id == 3) && (
                  <TabPanel value={tabValue} index={6}>
                      <SpecialOrderPricingRuleTable title="Pricing Rules"/>
                  </TabPanel>
              )}
              {(["Admin", "SuperAdmin"].includes(user.roles) || user?.type?.id == 2 || user?.type?.id == 3) && (
                  <TabPanel value={tabValue} index={7}>
                      <RequirementsTable title="Requirements"/>
                  </TabPanel>
              )}
          </Paper>
        </Container>
      </Box>
      <UpdateSchoolDialog
          currentSchool={school?.data}
          open={openInstitutionalProfile}
          handleClose={handleCloseEditInstitutionalProfile}
      />
      <AddEditProfile open={openAddUser} handleClose={handleCloseAddUser}/>
      <ChangePassword
          open={openChangePassword}
          handleClose={handleCloseChangePassword}
      />
    </>
  );
};

export default AccountSetting;
