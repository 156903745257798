import React from "react";
import moment from "moment-timezone";

export function InstitutionalProfile(props: { school: any }) {
    const { school } = props;

    return (
        <div className="bg-white-50 min-h-screen py-3">
            {/* Card Container */}
            <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg border border-gray-200 p-8">
                {/* Header */}
                <div className="mb-8">
                    <h1 className="text-2xl font-bold text-gray-800">Institutional Profile</h1>
                    <p className="text-gray-500">
                        View and manage the institutional details below.
                    </p>
                </div>

                {/* Grid Layout */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* First Column */}
                    <div className="space-y-4">
                        <ProfileItem label="School ID/Code" value={school?.code || school?.institutionalCode} />
                        <ProfileItem label="School Name" value={school?.name} />
                        <ProfileItem
                            label="School Address"
                            value={`${school?.address || ""} ${school?.street || ""}, ${school?.municipalityCity || "-"}`}
                        />
                        <ProfileItem label="School Email" value={school?.email} />
                        <ProfileItem label="School Fax" value={school?.fax} />
                        <ProfileItem label="School Zip" value={school?.zipCode} />
                        <ProfileItem
                            label="Approved Date"
                            value={
                                school?.approvedDate
                                    ? moment.utc(school.approvedDate).tz("Asia/Manila").format("MMMM D, YYYY, h:mm a")
                                    : "-"
                            }
                        />
                        <ProfileItem label="Approved By" value={school?.approvedBy} />
                    </div>

                    {/* Second Column */}
                    <div className="space-y-4">
                        <ProfileItem label="Institutional Head Telephone" value={school?.institutionalHeadTelephone} />
                        <ProfileItem
                            label="Institutional Form of Ownership"
                            value={school?.institutionalFormOfOwnership}
                        />
                        <ProfileItem label="Name of Institutional Head" value={school?.nameofInstitutionalHead} />
                        <ProfileItem
                            label="Title of Head of Institution"
                            value={school?.titleofHeadofInstitution}
                        />
                        <ProfileItem
                            label="Highest Educational Attainment of the Head"
                            value={school?.highestEducationalAttainmentoftheHead}
                        />
                    </div>

                    {/* Third Column */}
                    <div className="space-y-4">
                        <ProfileItem label="Year Established" value={school?.yearEstablished} />
                        <ProfileItem label="Sec Registration" value={school?.secRegistration} />
                        <ProfileItem
                            label="Year Converted to College Status"
                            value={school?.yearConvertedtoCollegeStatus}
                        />
                        <ProfileItem
                            label="Year Converted to University Status"
                            value={school?.yearConvertedtoUniversityStatus}
                        />
                        <ProfileItem label="Longitude" value={school?.longitude} />
                        <ProfileItem label="Latitude" value={school?.latitude} />
                        {school?.province?.name && <ProfileItem label="Province" value={school?.province?.name} />}
                        {school?.institutionalTypeForOT && (
                            <ProfileItem label="Institutional Type (For OT)" value={school?.institutionalTypeForOT} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ProfileItem({ label, value }: { label: string; value: string }) {
    return (
        <div>
            <h3 className="text-sm font-medium text-gray-600">{label}</h3>
            <div className="mt-1 px-4 py-2 bg-blue-50 text-blue-600 rounded-md text-sm font-semibold truncate">
                {value || "-"}
            </div>
        </div>
    );
}
