import {BookmarkRounded, GrainRounded, SchoolRounded,} from "@mui/icons-material";
import {Box, Breadcrumbs, Button, Container, Link, Paper, Tab, Tabs, Typography,} from "@mui/material";
import {useCallback, useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {useHistory, useParams} from "react-router-dom";
import CourseTable from "../components/Tables/CourseTable";
import DisciplineTable from "../components/Tables/DisciplineTable";
import CurriculumTable from "../components/Tables/CurriculumTable";
import ProgramTable from "../components/Tables/ProgramTable";
import UserTable from "../components/Tables/UserTable";
import TabPanel from "../components/TabPanel";
import {useAppSelector} from "../redux/store";
import {useDispatch} from "react-redux";
import {setClear, setSchoolSelectorById, setTabValue} from "../redux/features/school";
import ReportTable from "../components/Tables/ReportTable";
import {useSchoolIdQuery} from "../hooks/useSchoolIdQuery";
import {InstitutionalProfile} from "./InstutionalProfile.page";
import useDialog from "../hooks/useDialog";
import UpdateSchoolDialog from "../components/dialogs/UpdateSchool.dialog";
import {getSchoolsById} from "../requests/school.request";
import MajorTable from "../components/Tables/MajorTable";
import {useLocation} from "react-router";
import ConfigurationRequirementTable from "../components/Tables/ConfigurationRequirementTable";

const SchoolPage = () => {
  const history = useHistory<any>();
  const {schoolId} = useParams() as {
    schoolId: string
  };

  const navigate = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const tabValue = useAppSelector((store) => store?.school.tabValue);
  const disciplineName = useAppSelector(
      (store) => store?.school.disciplineName
  );
  const programName = useAppSelector((store) => store?.school.programName);
  const curriculumName = useAppSelector(
      (store) => store?.school.curriculumName
  );
  const selectedSchool = useAppSelector(
    (store) => store?.school.selectedSchool
  );

  const { data: school, isLoading } = useSchoolIdQuery(
      schoolId
  );




  const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);

  useEffect(() => {
    var query = new URLSearchParams(location.search)
    const page = query.get('page') || '1';
    dispatch(setClear());
    dispatch(setTabValue(page));
  }, []);
  const handleViewSchool = useCallback(
    ({
      school,
      schoolId,
      title,
      region,
      institutionalCode,
    }: {
      school: any;
      schoolId: number;
      title: string;
      region: string;
      institutionalCode: string;
    }) => {
      history.push(`/schools/${schoolId}`, {
        schoolId,
        region,
        institutionalCode,
        from: "/schools",
        title,
      });
    },
      []
  );
  const roles = useAppSelector((store) => store?.auth?.user?.roles);
  const {
    handleOpen: handleOpenEditInstitutionalProfile,
    handleClose: handleCloseEditInstitutionalProfile,
    open: openInstitutionalProfile,
  } = useDialog();
  const schoolSelector = useAppSelector(
      (store) => store?.auth?.user?.school
  );
  useEffect(() => {
    getSchoolsById(schoolSelector?.code).then(c => dispatch(setSchoolSelectorById(c?.[0])))
  }, [schoolSelector])
  const access: any = ["Admin", "SuperAdmin"];
  return (
      <>
        <Box
            component="main"
            sx={{flexGrow: 1, pt: 10}}
            style={{paddingLeft: " 20px", paddingRight: "20px"}}
        >
          <Container>
            <Helmet>
            <title>School Institutional Profile</title>
          </Helmet>

          <Box component="main">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box>
                <Typography variant="h5" fontWeight="bold">
                  School Institutional Profile
                </Typography>
                {/* <Typography variant="body1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, dolor. Rerum expedita praesentium animi error.</Typography>
                 */}{" "}
              </Box>
            </Box>
            <Paper>
              <Breadcrumbs sx={{ p: 2 }}>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/schools"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(history?.location?.state?.from);
                  }}
                >
                  <SchoolRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                  Schools
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <GrainRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                  {history?.location?.state?.title}
                </Typography>

                {disciplineName ? (
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    <BookmarkRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                    {disciplineName}
                  </Typography>
                ) : null}
                {programName ? (
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    <BookmarkRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                    {programName}
                  </Typography>
                ) : null}

                {curriculumName ? (
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    <BookmarkRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                    {curriculumName}
                  </Typography>
                ) : null}
              </Breadcrumbs>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                  value={tabValue}
                  onChange={(e, value) => {
                    const newParams = { page: value ?? "6" }
                    const searchParams = new URLSearchParams(location.search);
                    dispatch(setClear());
                    dispatch(setTabValue(value));

                    handleViewSchool.bind(null, {
                      school: selectedSchool,
                      schoolId: selectedSchool?.id,
                      title: selectedSchool?.name,
                      region: selectedSchool?.region?.name,
                      institutionalCode: selectedSchool?.institutionalCode,
                    });
                    Object.keys(newParams).forEach(key => {
                      if ((newParams as any)[key] === null) {
                        searchParams.delete(key);
                      } else {
                        searchParams.set(key, (newParams as any)[key]);
                      }
                    });


                    navigate.replace(`${location.pathname}?${searchParams.toString()}`, { replace: true });
                  }}
                >
                    <Tab label="Majors" value={"6"}/>
                    <Tab label="Disciplines" value={"0"}/>
                  <Tab label="Programs" value={"1"} />
                  <Tab label="Curriculum" value="curriculum" />
                    <Tab label="Courses" value={"2"}/>

                    {
                        typeId == 2 && roles.toLowerCase() == "admin" ?
                            <Tab label="Institutional Profile" value={5}/> : null
                    }
                  {
                    typeId == 2 && roles.toLowerCase() == "admin" ?
                        <Tab label="Configuration Requirements" value={"7"}/>: null
                  }
                    <Tab label="Download" value={"4"} />
                </Tabs>
              </Box>


                <TabPanel value={tabValue} index={"6"}>
                    <MajorTable schoolRegionId={school?.data?.regionId} title="Majors Table"/>
                </TabPanel>

                {tabValue === 5 && !(typeId == 1 && roles?.toLowerCase() == "user") && (
                    <Box
                        style={{
                            padding: 12,
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end"
                        }}>
                        <Button id={"EditInstitutionalProfile"}
                                onClick={handleOpenEditInstitutionalProfile}
                                variant="contained"
                        >
                            Edit Institutional Profile
                        </Button>
                  </Box>
              )}
              <TabPanel value={tabValue} index={"0"}>
                <DisciplineTable schoolRegionId={school?.data?.regionId}  title="Disciplines Table" />
              </TabPanel>
              <TabPanel value={tabValue} index={"1"}>
                <ProgramTable title="Programs Table"/>
              </TabPanel>
              <TabPanel value={tabValue} index="curriculum">
                <CurriculumTable title="Curriculums Table" />
              </TabPanel>
              { typeId == 2 && roles.toLowerCase() == "admin" ?
                <TabPanel value={tabValue} index={"5"}>
                  <Box sx={{padding: 5}}>
                    <InstitutionalProfile school={school?.data}/>
                  </Box>

                </TabPanel> : null
              }

              <TabPanel value={tabValue} index={"2"}>
                <CourseTable title="Courses Lists" />
              </TabPanel>

              {access.indexOf(roles) > -1 ? (
                <TabPanel value={tabValue} index={"3"}>
                  <UserTable title="Users" />
                </TabPanel>
              ) : null}
              { typeId == 2 && roles.toLowerCase() == "admin" ?
              <TabPanel value={tabValue} index={"7"}>
                <ConfigurationRequirementTable title="Configuration Requirements"/>
              </TabPanel> : null }
              <TabPanel value={tabValue} index={"4"}>
                <ReportTable title="Download Reports" />
              </TabPanel>

            </Paper>
          </Box>
            {openInstitutionalProfile ? <UpdateSchoolDialog
              currentSchool={school?.data}
              open={openInstitutionalProfile}
              handleClose={handleCloseEditInstitutionalProfile}
          /> : null}
        </Container>
      </Box>
    </>
  );
};

export default SchoolPage;
