import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import Dialog, {DialogProps} from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Slide from "@mui/material/Slide";
import MenuItem from "@mui/material/MenuItem";
import {TransitionProps} from "@mui/material/transitions";
import {Alert, Box, CircularProgress, OutlinedInput, Select, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useQueryClient} from "react-query";
import {postCourse} from "../../requests/course.request";
import {useAddEntities} from "../../hooks/useAddEntities";
import {SearchSchoolResponseType} from "../../types";
import SearchCurriculumTextField from "../SearchTextField/SearchCurriculumTextField";
import {setCurriculum, setCurriculumBreadcrumb, setCurriculumId, setCurriculumTemp,} from "../../redux/features/school";
import {RootState, useAppSelector} from "../../redux/store";
import {setErrorSnackbar} from "../../helper/utils/helper";
import FormControl from "@mui/material/FormControl";
import {usePrerequisiteQuery} from "../../hooks/usePrerequisiteQuery";
import {usePrerequisiteByCourseIdQuery} from "../../hooks/usePrerequisiteByCourseIdQuery";
import {CheckRounded} from "@mui/icons-material";
import SearchAsYouType from "../SearchAsYouType";
import {LoadingButton} from "@mui/lab";
import {setColoredRows} from "../../redux/features/curriculum";

const validationSchema = yup.object({
  code: yup.string().required("Code is required"),
  name: yup.string().required("Name is required"),
  description: yup.string().nullable(),
  lecture: yup.number().required("Lecture is required"),
  laboratory: yup.number().required("Laboratory is required"),
  curriculumId: yup.number().nullable(),
  prerequisite: yup.string().nullable(),
  prerequisiteCourseCodes: yup.array().nullable(),
  yearLevel: yup.number().required("Year Level id is required"),
  programId: yup.number().nullable(),
  programName: yup.string().nullable(),
  levelId: yup.number().nullable(),
  levelName: yup.string().nullable(),
  disciplineId: yup.number().nullable(),
  disciplineName: yup.string().nullable(),
  majorId: yup.number().nullable(),
  majorName: yup.string().nullable(),
  courseSemester: yup.number().required("Course Semester id is required"),
});

const initialValues = {
  schoolId: "",
  curriculumName: "",
  yearLevel: 0,
  courseSemester: 0,
  programId: 0,
  programName: "",
  levelId: 0,
  levelName: "",
  disciplineId: 0,
  disciplineName: "",
  majorId: 0,
  majorName: "",
  code: "",
  name: "",
  description: "",
  curriculumId: "",
  lecture: "",
  laboratory: "",
  prerequisite: "",
  prerequisiteCourseCodes: [] as string[]
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  open: boolean;
  handleClose: () => void;
  setting?:boolean;
}

const AddCourse = React.memo((props: IProps) => {
  const {open, handleClose, setting} = props;
  const dispatch = useDispatch();
  const [school, setSchool] = useState<any>()
  const queryClient = useQueryClient();
  const {schoolId, regionId} = useAddEntities();
  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);
  const [loading, setLoading] = useState(false)
  const tempCurriculum = useAppSelector(
      (store) => store?.school?.curriculumTemp
  );

  const curriculumCount = useAppSelector(
      (store) => store?.curriculum?.curriculumCount
  );
  const curriculumName = useAppSelector(
      (store) => store?.school?.curriculumName
  );
  const curriculumId = useAppSelector(
      (store) => store?.school?.curriculumId
  );
  const curriculum = useAppSelector(
      (store) => store?.school?.curriculum
  );




const resetForm = () => {
  formik.setFieldValue("schoolId", "", false);
  formik.setFieldValue("curriculumName", "", false);
  formik.setFieldValue("yearLevel", 0, false);
  formik.setFieldValue("courseSemester", 0, false);
  formik.setFieldValue("code", "", false);
  formik.setFieldValue("name", "", false);
  formik.setFieldValue("description", "", false);
  formik.setFieldValue("programId",  0);
  formik.setFieldValue("programName", "");
  formik.setFieldValue("majorId",  0);
  formik.setFieldValue("majorName", "");
  formik.setFieldValue("levelId",  0);
  formik.setFieldValue("levelName", "");
  formik.setFieldValue("disciplineId",  0);
  formik.setFieldValue("disciplineName", "");
  formik.setFieldValue("lecture", "", false);
  formik.setFieldValue("laboratory", "", false);
  formik.setFieldValue("prerequisite", "", false);
  formik.setFieldValue("prerequisiteCourseCodes", [], false);
  }


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {

      if (!values.courseSemester) {
        setSnackbarStore({
          message: "Course Semester is required",
          severity: "error",
          open: true,
        })
        return
      }

      if (!values.yearLevel) {
        setSnackbarStore({
          message: "Year Level is required",
          severity: "error",
          open: true,
        })

        return
      }

      try {
        if (!setting) {
          values.schoolId = schoolId;
        } else if (setting) {
          values.schoolId = schoolId ? schoolId : school?.id
        }
        values.laboratory = values.laboratory?.toString();
        values.lecture = values.lecture?.toString();
        if (values.prerequisite) {
          values.prerequisiteCourseCodes = values.prerequisite.split(/\s*(?:,|$)\s*/)
        }

        setLoading(true)

        var res = await postCourse(values);
        dispatch(setColoredRows(values?.code ?? "" + values?.name ?? "" + values?.description ?? ""))

        setLoading(false)
        resetForm()
        if (res.message) {
          var __message = values?.name + " already exists in our system. Please try a different one."

          setSnackbarStore({
            message: res.message,
            severity: __message == res.message ? "error" : "success",
            open: true,
          });

        } else {
          setSnackbarStore({
            message: "Successfully added course",
            severity: "success",
            open: true,
          })
        }

        setLoading(false)
          await queryClient.invalidateQueries("courses");

        if (curriculumName != null) {
          dispatch(setCurriculumId(values?.curriculumId));
          dispatch(setCurriculumBreadcrumb(values?.curriculumName));
          dispatch(setCurriculum(tempCurriculum));
        }

        handleClose();
      } catch (error: any) {
        setLoading(false)
        setErrorSnackbar(error, setSnackbarStore);
      }
    },
  });

  const { data: prerequisiteData, refetch, isLoading: isLoadingPrerequite } = usePrerequisiteQuery({
    ...formik.values.curriculumName && {curriculumName: formik.values.curriculumName}
  });


  const { data: prerequisiteByCourseIdData } = usePrerequisiteByCourseIdQuery({
    ...formik.values.curriculumId && {courseId: formik.values.curriculumId}
  });



  const handleSubmit = React.useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const handleOnSelected = useCallback(
      (value: any) => {

        dispatch(setCurriculumTemp(value));
        formik.setFieldValue("programId", value?.programId ?? "");
        formik.setFieldValue("programName", value?.programName ?? "");
        formik.setFieldValue("levelId", value?.levelId ?? 0);
        formik.setFieldValue("levelName", value?.levelName ?? "");
        formik.setFieldValue("disciplineId", value?.disciplineId ?? 0);
        formik.setFieldValue("disciplineName", value?.disciplineName ?? "");
        formik.setFieldValue("majorId", value?.majorId ?? 0);
        formik.setFieldValue("majorName", value?.majorName ?? "");
        formik.setFieldValue("levelId", value?.levelId ?? 0);
        formik.setFieldValue("levelName", value?.levelName ?? "");
        formik.setFieldValue("curriculumId", value?.curriculumId ?? "");
        formik.setFieldValue("curriculumName", value?.curriculumName ?? "");
        if(value?.curriculumId == null){
          formik.setFieldValue('prerequisiteCourseCodes',  [])
        }

      },
      [formik]
  );



  useEffect(() => {
   formik.setFieldValue('curriculumId',   tempCurriculum?.curriculumId ?? curriculumId)
    dispatch(setCurriculumTemp(curriculum));
    formik.setFieldValue("programId", curriculum?.programId ?? "");
    formik.setFieldValue("programName", curriculum?.programName ?? "");
    formik.setFieldValue("levelId", curriculum?.levelId ?? 0);
    formik.setFieldValue("levelName", curriculum?.levelName ?? "");
    formik.setFieldValue("disciplineId", curriculum?.disciplineId ?? 0);
    formik.setFieldValue("disciplineName", curriculum?.disciplineName ?? "");
    formik.setFieldValue("majorId", curriculum?.majorId ?? 0);
    formik.setFieldValue("majorName", curriculum?.majorName ?? "");
    formik.setFieldValue("levelId", curriculum?.levelId ?? 0);
    formik.setFieldValue("levelName", curriculum?.levelName ?? "");
    formik.setFieldValue("curriculumId", curriculum?.curriculumId ?? "");
    formik.setFieldValue("curriculumName", curriculum?.curriculumName ?? "");
  }, [curriculum])

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue('prerequisiteCourseCodes', typeof value === 'string' ? value.split(',') : value)

  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [names , setName] = useState<{id: number, courseCode: string}[]>([])
  useEffect(() =>{

    if(prerequisiteData?.data){
      setName(prerequisiteData?.data)
    }

  }, [prerequisiteData, prerequisiteByCourseIdData, formik.values.curriculumName, names ])


  const prerequisites = useMemo(() => names?.map((c: any) => {
       return {
      program: c.program,
      curriculumName: c?.curriculumName,
      courseName: c?.courseName,
      courseDescription: c?.courseDescription,
      courseCode: c?.courseCode,
      value: c.courseCode,
      id: c.id,
    }
  }), [names, prerequisiteData?.data, prerequisiteByCourseIdData]);
  const type = useAppSelector((store: RootState) => store.auth?.user?.type)
  const roles = useAppSelector((store) => store?.auth?.user?.roles) as
      | "User"
      | "Admin"
      | "SuperAdmin";



  const [onErrorSelectedSchool, setOnErrorSelectedSchool] = useState<any>(false)
  const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {
    setSchool(value)
    formik.setFieldValue('schoolId', value?.id ?? "")
  }, [formik])
  const authStore = useSelector((store: RootState) => store.auth);
  const handleCloseBackdrop: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    handleClose()
    resetForm()

  };
  return (
      <Dialog
          open={open}
          TransitionComponent={Transition}
          // keepMounted
          onClose={handleCloseBackdrop}
          maxWidth="md"
          fullWidth
          aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Add Course <p style={{margin: 0,fontSize: 12, fontStyle: "italic"}}>({authStore?.user?.school?.name ?? ""})</p></DialogTitle>
        <DialogContent>
          <Alert severity="info">
            Please fill in the details of the course you want to add
            <br />
            Note: Fields with <strong>*</strong> are required
          </Alert>
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 3,
              }}
          >
            <Stack direction="row" gap={1}>
              { type?.id == 2 && (roles == "User" || roles == "Admin" || roles == "SuperAdmin") ?   <Box paddingBottom={1}>
                {<SearchAsYouType
                    id={"courseselect"}
                    required={true}
                    error={onErrorSelectedSchool}
                    label='School Institutional Name'
                    onSelected={handleOnSelectedSchool}
                /> }
              </Box> : null
              }
              <Box
                  sx={{
                    width: "100%",
                  }}
              >
                <SearchCurriculumTextField

                    required={true}
                    label="Curriculum"
                    onSelected={handleOnSelected}
                    schoolId={schoolId}
                />
              </Box>
              <TextField
                  select
                  label="Year Level"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  required={true}
                  id="yearLevel"
                  name="yearLevel"
                  value={formik.values.yearLevel}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.yearLevel && Boolean(formik.errors.yearLevel)
                  }
                  helperText={formik.touched.yearLevel && formik.errors.yearLevel}
              >
                <MenuItem value={1}>1st Year</MenuItem>
                <MenuItem value={2}>2nd Year</MenuItem>
                <MenuItem value={3}>3rd Year</MenuItem>
                <MenuItem value={4}>4th Year</MenuItem>
                <MenuItem value={5}>5th Year</MenuItem>
              </TextField>
              <TextField

                  select
                  required={true}
                  label="Semester"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  id="courseSemester"
                  name="courseSemester"
                  value={formik.values.courseSemester}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.courseSemester &&
                      Boolean(formik.errors.courseSemester)
                  }
                  helperText={
                      formik.touched.courseSemester && formik.errors.courseSemester
                  }
              >
                <MenuItem value={1}>1st Semester</MenuItem>
                <MenuItem value={2}>2nd Semester</MenuItem>
                <MenuItem value={3}>Mid Year / Summer</MenuItem>
              </TextField>
            </Stack>
            <TextField
                label="Code"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                required
                id="code"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
            />
            <TextField
                label="Name"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                required
                id="coursename"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
                label="Description"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                required
                id="coursedescription"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                    formik.touched.description && Boolean(formik.errors.description)
                }
                helperText={formik.touched.description && formik.errors.description}
            />


            <FormControl sx={{  width: "100%",}}>

              <Select  aria-label={"course-select"}
                  inputProps={{
                    'id': `course-select-role`,
                    'aria-label': 'Without label'
                  }}

                  SelectDisplayProps={{
                    // @ts-ignore
                    "id": `course-select-role`
                  }}

                  id="course-select-role"
                  //disabled={!( formik?.values?.curriculumId) }
                  size={"small"}
                  multiple
                  displayEmpty
                  value={formik.values.prerequisiteCourseCodes}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {

                    return selected.join(', ');
                  }}
                  MenuProps={MenuProps}
              >
                <MenuItem disabled value="">
                  <em>Select Prerequisite</em>;
                </MenuItem>
                  <MenuItem onClick={() => {
                    formik.setFieldValue("prerequisiteCourseCodes", []);
                    formik.validateForm();
                  }}>
                    <Button  sx={{ display: 'block'}}
                            disabled={formik.values.prerequisiteCourseCodes.length === 0}>
                      <p  style={{color: "blue"}}>
                        Clear Prerequisites
                      </p>
                    </Button>
                  </MenuItem>
                {isLoadingPrerequite ?
                    <MenuItem onClick={() => {

                      formik.setFieldValue("prerequisiteCourseCodes", [])
                    }}>
                      <Button  sx={{ display: 'block'}}
                               disabled={formik.values.prerequisiteCourseCodes.length === 0}>
                       <CircularProgress/>
                      </Button>
                    </MenuItem>
                    : null}
                {formik.values.curriculumName ? prerequisites?.sort((a: any, b:any) => {
                  let fa = a?.value.toLowerCase(),
                      fb = b?.value.toLowerCase();

                  if (fa < fb) {
                    return -1;
                  }
                  if (fa > fb) {
                    return 1;
                  }
                  return 0;
                })?.map((name: any) => {
                  return  <MenuItem
                      key={name.id}
                      value={name.value}
                      aria-label={name.value}
                  >
                    <div style={{justifyContent: "space-between", flex: 1, display: "flex", flexDirection: "row"}}>
                      <div>
                        <div style={{fontWeight: "bold",}}>
                          {name.value}
                        </div>

                        <p style={{
                          fontStyle: "italic",
                          margin: 0,
                          fontSize: 12
                        }}>{name?.program ? name.program + " - " : ""} {name?.curriculumName ? "\nCurriculum Name: " + name.curriculumName : "-"}</p>
                        <p style={{
                          fontStyle: "italic",
                          margin: 0,
                          fontSize: 12
                        }}>{name?.curriculumName ? "\nCurriculum Name: " + name.curriculumName : "-"}</p>
                        <p style={{
                          fontStyle: "italic",
                          margin: 0,
                          fontSize: 12
                        }}>{name?.courseCode ? "\nCourse Code: " + name.courseCode + " - " : ""}</p>
                        <p style={{

                          fontStyle: "italic",
                          margin: 0,
                          fontSize: 12
                        }}>{name?.courseName ? "\nCourse Name: " + name?.courseName + "" : ""}</p>

                        <p style={{

                          fontStyle: "italic",
                          margin: 0,
                          fontSize: 12
                        }}> {name?.courseDescription ? "Description: " + name?.courseDescription + "" : ""}</p>
                      </div>

                      {formik?.values?.prerequisiteCourseCodes?.findIndex((c: any) => c == name.value) > -1 ? <div>
                        <CheckRounded color={"success"}/>
                      </div> : null}
                    </div>
                  </MenuItem>
                }) : null}
              </Select>
            </FormControl>
            <Stack direction="row" gap={1}>
              <TextField
                  type="number"
                  InputProps={{
                    inputProps: {min: 0},
                  }}
                  inputMode={"numeric"}
                  label="Number (units) of Lecture"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  required
                  id="lecture"
                  name="lecture"
                  value={formik.values.lecture}
                  onChange={formik.handleChange}
                  error={formik.touched.lecture && Boolean(formik.errors.lecture)}
                  helperText={formik.touched.lecture && formik.errors.lecture}
              />

              <TextField
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  inputMode={"numeric"}
                  label="Number (units) of Laboratory "
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  required
                  id="laboratory"
                  name="laboratory"
                  value={formik.values.laboratory}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.laboratory && Boolean(formik.errors.laboratory)
                  }
                  helperText={formik.touched.laboratory && formik.errors.laboratory}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button id={"cancelCourse"} onClick={()=> {
            handleClose()
            resetForm()
          }}>Cancel</Button>
          <LoadingButton loading={loading} id={"addCourse"}
                         disabled={!(formik.isValid && formik.dirty)}
                         variant="contained"
                         onClick={handleSubmit}
          >
            {loading ? "Adding" : "Add"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
  );
});

export default AddCourse;
