import {Autocomplete, Box, Card, Grid, Paper, TextField, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {styled, useTheme} from "@mui/material/styles";
import useUploadPromotionalReportDialog from "../components/dialogs/UploadPromotionalReport.dialog";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import React, {useEffect, useMemo, useRef, useState} from "react";
import ActiveSchool from "../svg/activeschool";
import SubmittedIcon from "../svg/sumbitted";
import PendingIcon from "../svg/pending";
import GraduateIcon from "../svg/graduate";
import EnrollmentIcon from "../svg/enrolles";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import {getEnrolleesCount, getGraduatesCount,} from "../requests/student.request";
import {getSchoolCount} from "../requests/school.request";
import {mergeArrays} from "../helper/utils/helper";
import AppPieChart from "../components/Dashboard/AppPieChart";
import {useHistory} from "react-router-dom";
import {useSchoolYearSelectQuery} from "../hooks/useSchoolQuery";
import _ from "lodash";
import {usePromotionalReportCountQuery} from "../hooks/usePromotionalReportCountQuery";
import {usePromotionalReportChartDataQuery} from "../hooks/usePromotionalReportChartDataQuery";
import {Skeleton} from "@mui/lab";
import {useCourseCountQuery} from "../hooks/useCourseCount";
import {useCurriculumCountQuery} from "../hooks/useCurriculumCount";
import {useProgramCountQuery} from "../hooks/useProgramCount";
import ProgramSvg from "../assets/program";
import CurriculumSvg from "../assets/curriculum";
import CourseSvg from "../assets/course";

const DashboarPage = () => {
    const {
    WarningComponent,
        ErrorComponent,
        Component: UploadDialog,
        handleClickOpen,
        handleClose,
    } = useUploadPromotionalReportDialog();
    const month = [
        "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const chartReference = useRef<any>();
  const [loaded, setLoaded] = useState<boolean>();

  useEffect(() => {
    setLoaded(true);
  }, []);
    const history = useHistory();
  const [data, setData] = useState<any>(
    month.map((m, index) => {
      return {
        label: m,
        year: 0,
        "PR Count": 0,
      };
    })
  );
  const [schoolCount, setSchoolCount] = useState<any>(0);
  const [graduatesCount, setGraduatesCount] = useState<any>(0);
  const [enrolleesCount, setEnrolleesCount] = useState<any>(0);
  const [submitted, setSubmitted] = useState<any>(0);
    const [pending, setPending] = useState<any>(0);
    const [countLoaded, setCountLoaded] = useState<any>(false);
    const currentYear = useMemo(() => {
        return new Date().getFullYear();
    }, []);
  const [lineCount, setLineCount] = useState(0);
    const [selectedSchoolYear, setSelectedSchoolYear] = React.useState<any>( {
        id: undefined,
        name: "All"
    });
    const [selectedSemester, setSelectedSemester] = React.useState<any>({
        id: undefined,
        name: "All"
    });

    const regionId = useSelector((store: RootState) => store.auth?.user?.region?.id)
    const { data: schoolYear, isLoading: schoolYearIsLoading} = useSchoolYearSelectQuery()
    const semesterMemo = useMemo(() => {
        return [
            {
                id: null,
                name: "All"
            },
            {
                id: 1,
                name: "1st Semester"
            },
            {
                id: 2,
                name: "2nd Semester"
            },
            {
                id: "SUMMER",
                name: "MidYear (SUMMER)"
            }
        ]
    }, [])


    var startDate = new Date(currentYear, 0, 1)?.toISOString();
    var endDate = new Date(currentYear, 11, 31)?.toISOString();
    const {data: promotionalReportCount, isLoading: isCountLoaded} = usePromotionalReportCountQuery({
        startDate,
        endDate,
        selectedSemester,
        selectedSchoolYear,
        regionId
    });

    const {data: courseCount, isLoading: isCourseCountLoaded} = useCourseCountQuery();
    // const {data: programCourseSankey, isLoading: isProgramCourseSankey} = useProgramCourseSankeyQuery();
    const {data: programCount, isLoading: isProgramCountLoaded} = useProgramCountQuery();
    const {data: programApprovedCount, isLoading: isProgramApproveCountLoaded} = useProgramCountQuery("approved");
    const {data: programSubmittedCount, isLoading: isProgramSubmittedCountLoadedprogramCourseSankey} = useProgramCountQuery("submitted");
    const {data: programDraftCount, isLoading: isProgramDraftCountLoaded} = useProgramCountQuery("draft");
    const {data: curriculumCount, isLoading: isCurriculumCountLoaded} = useCurriculumCountQuery();
    const {
        data: promotionalReportChartDataQuery,
        isLoading: isChartDataLoaded
    } = usePromotionalReportChartDataQuery({startDate, endDate, selectedSemester, selectedSchoolYear, regionId});

    useEffect(() => {

        var data: any = [];

        setSubmitted(0);
        setPending(0);
        setData([]);
        data = month.map((m, index) => {
            var r = promotionalReportCount?.data?.find((c: any) => c.month == index + 1);
            return {
                label: m,
                year: r?.year || 0,
                PR: r?.count || 0,
            };
        });






                var prChartData: any = [];
                var pending: any = 0;
                var submitted: any = 0;
        promotionalReportChartDataQuery?.data?.dataSets.map((ds: any, index: number) => {
            for (let i = 0; i < ds.data.length; i++) {
                if (ds.label == "Submitted") {
                    submitted += ds.data[i];
                } else if (ds.label == "Pending") {
                    pending += ds.data[i];
                }
                prChartData[i] = {
                    ...prChartData[i],
                    label: month[i],
                    [ds.label]: ds.data[i],
                        };
                    }
                });
                setSubmitted(submitted);
                setPending(pending);
                setData(mergeArrays([data, prChartData], "label"));





        try {
            setCountLoaded(true)
            var semesterName;
            var schoolName;

            if(semesterMemo.findIndex(c => c.id == selectedSemester?.id) > -1){
                semesterName = semesterMemo[semesterMemo.findIndex(c => c.id == selectedSemester?.id)].name;
            }
            if((schoolYear || []).findIndex(c => c.id == selectedSchoolYear?.id) > -1){
                schoolName = (schoolYear || [])[(schoolYear || []).findIndex(c => c.id == selectedSchoolYear?.id)].name;
            }
            getGraduatesCount(startDate, endDate, regionId, semesterName , schoolName ).then((res) => {
                setGraduatesCount(res);
                setCountLoaded(false)
            }).catch(e => {
                setCountLoaded(false)
            }).finally(() => {
                setCountLoaded(false)
            });

        } catch (e) {

            setCountLoaded(false)
        } finally {
            setCountLoaded(false)
        }


        try {

            getEnrolleesCount(startDate, endDate, regionId, selectedSemester, selectedSchoolYear ).then((res) => {
                setEnrolleesCount(res.data);
                setCountLoaded(false)
            }).catch(e => {
                setCountLoaded(false)
            }).finally(() => {
                setCountLoaded(false)
            });
            getSchoolCount(startDate, endDate, regionId).then((res) => {
                setSchoolCount(res.data);
            });

        } catch (e) {

            setCountLoaded(false)
        } finally {
            setCountLoaded(false)
        }


    }, [promotionalReportCount, promotionalReportChartDataQuery, currentYear, selectedSemester, selectedSchoolYear, regionId]);


  const userState = useSelector((rootState: RootState) => rootState.auth.user);
    const theme = useTheme();




  const role = useMemo(() => {
    return {
      Admin: (
        <>
          <Box style={{    cursor: "pointer",display: "flex", flex: 1 }}>
            <Box
              boxShadow={1}
              style={{
                justifyContent: "space-between",
                backgroundColor: "#fff",
                display: "flex",
                flex: 1,
                  cursor: "pointer",
                  paddingRight: 10,
                  paddingLeft: 10
              }}
            >

                    {data ? (
                        <Grid  container>
                            <Grid item xs={12} md={12} lg={12}>
                                <Box
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >

                                        <Typography variant="overline" style={{fontSize: 20, fontWeight: 'bold'}}>
                                            Statistics
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 1,
                                            justifyContent: "center",
                                            padding: 10,
                                            flexDirection: "row",
                                            display: "flex",
                                        }}
                                    >
                                        <AppPieChart
                                            noContent={+graduatesCount + enrolleesCount}
                                            loading={isCountLoaded}
                                            title=""
                                            chartData={[
                                                {label: 'Graduates', value: +graduatesCount},
                                                {label: 'Enrollees', value: enrolleesCount},
                                            ]}
                                            chartColors={[
                                                "#CE2028",
                                                "#034F8B",
                                            ]}/>
                                    </Box>
                                </Box>
                                <div style={{
                                    paddingBottom: 12,
                                    paddingTop: 12,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                        <>
                                            <Autocomplete
                                                id={"dashboardschoolyear"}

                                                disabled={schoolYearIsLoading}
                                                disableClearable
                                                size="small"
                                                value={selectedSchoolYear}
                                                onChange={(event: any, newValue: string | null) => {
                                                    setSelectedSchoolYear(newValue ? newValue : {});
                                                }}
                                                getOptionLabel={(option) => {
                                                    return option.name
                                                }
                                                }
                                                renderOption={(props, option) => (
                                                    <li {...props} id={"settingdashboard" + option.id} key={option.id}>
                                                        <div
                                                            style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                            {option.name}</div>
                                                    </li>
                                                )}
                                                options={[{
                                                    name: "All",
                                                    id: undefined
                                                }, ...(_.uniqWith(schoolYear?.map((c: any, index: number) => {
                                                    return c
                                                }), function (arrVal: any, othVal: any) {
                                                    return arrVal?.name === othVal?.name;
                                                }) || [])] || []}
                                                style={{width: 300, marginLeft: 6}}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Academic Year "/>
                                                )}
                                            />
                                        </>
                                    </form>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                        <>
                                            <Autocomplete
                                                id={"dashboardschoolyearsemester"}
                                                disabled={schoolYearIsLoading}
                                                disableClearable
                                                size="small"
                                                value={selectedSemester}
                                                onChange={(event: any, newValue: string | null) => {
                                                    setSelectedSemester(newValue ? newValue : {});
                                                }}
                                                getOptionLabel={(option) => {
                                                    return option.name
                                                }
                                                }
                                                renderOption={(props, option) => (
                                                    <li {...props} id={"settindashboardg" + option.id} key={option.id}>
                                                        <div
                                                            style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                            {option.name}</div>
                                                    </li>
                                                )}
                                                options={[...(semesterMemo || [])] || []}
                                                style={{width: 300, marginLeft: 6}}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Semester"/>
                                                )}
                                            />
                                        </>
                                    </form>

                                </div>
                            </Grid>
                            {/*<Grid  item xs={12} md={12} lg={12}>*/}
                            {/*    {programCourseSankey ? <Chart*/}
                            {/*        chartType="Sankey"*/}
                            {/*        width="100%"*/}
                            {/*        height="500px"*/}
                            {/*        data={programCourseSankey.data}*/}
                            {/*        options={{}}*/}
                            {/*    /> : null}*/}
                            {/*</Grid>*/}

                        </Grid>
                    ) : null}


            </Box>
              <div
                  style={{
                      cursor: "pointer",
                      paddingLeft: 10,
                      flexDirection: "column",
                      display: "flex",
                      flex: 0.4,
                  }}
              >
                  <Card onClick={() => {

                      history.push("/students?status=complete")
                  }} style={{
                      cursor: "pointer", height: "50%", flex: 1,
                      display: "flex", alignItems: "center", backgroundColor: "#CE2028", marginBottom: 10
                  }}>
                      <Box
                          style={{
                              cursor: "pointer",
                              padding: 30,
                              flex: 1,

                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                          }}
                      >
                          <Box style={{cursor: "pointer", padding: 10}}>
                              <GraduateIcon/>
                          </Box>

                          <Box>
                              <Typography
                                  textAlign={"right"}
                                  style={{cursor: "pointer", lineHeight: "25px"}}
                                  fontWeight={600}
                                  fontSize={25}
                                  color={"#fff"}
                              >
                                  {+graduatesCount}
                              </Typography>

                              <Typography
                                  fontSize={12}
                                  color={"#fff"}
                                  textTransform="uppercase"
                              >
                                  No. of Graduates
                              </Typography>
                          </Box>
                      </Box>
                  </Card>
                  <Card onClick={() => {

                      history.push("/students?status=complete")

                  }} style={{
                      cursor: "pointer", minHeight: "60px",  height: "50%", flex: 1,
                      display: "flex", alignItems: "center", backgroundColor: "#034F8B"
                  }}>
                      <Box
                          style={{
                              cursor: "pointer",
                              padding: 30,
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                          }}
                      >
                          <Box style={{cursor: "pointer", padding: 10}}>
                              <EnrollmentIcon/>
                          </Box>

                          <Box>
                              <Typography
                                  textAlign={"right"}
                                  style={{cursor: "pointer", lineHeight: "25px"}}
                                  fontWeight={600}
                                  fontSize={25}
                                  color={"#fff"}
                              >
                                  {enrolleesCount}
                              </Typography>

                              <Typography
                                  fontSize={12}
                                  color={"#fff"}
                                  textTransform="uppercase"
                              >
                                  No. of Enrollees
                              </Typography>
                          </Box>
                      </Box>
                  </Card>
                  <div
                      style={{
                          paddingTop: "10px",
                          cursor: "pointer",
                          display: "flex",
                          flex: 0.4,
                      }}
                  >
                      <Card onClick={() => {

                          history.push("settings?page=1")

                      }} style={{
                          cursor: "pointer", flex: 1,
                          display: "flex",
                          padding: 20,
                          marginRight: 12,
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          backgroundColor: "#2b4d59"
                      }}>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.7,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Box style={{cursor: "pointer", padding: 10}}>
                                  <ProgramSvg/>
                              </Box>
                              <Box>
                                  <Typography
                                      textAlign={"right"}
                                      style={{cursor: "pointer", lineHeight: "25px"}}
                                      fontWeight={600}
                                      fontSize={25}
                                      color={"#fff"}
                                  >
                                      {programCount?.data ?? 0}
                                  </Typography>
                              </Box>
                          </Box>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.3,
                                  display: "flex",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Typography
                                  fontSize={12}
                                  color={"#fff"}
                                  align={"center"}
                                  textTransform="uppercase"
                              >
                                  No. of Programs
                              </Typography>
                          </Box>

                      </Card>

                      <Card onClick={() => {

                          history.push("settings?page=curriculum")

                      }} style={{
                          cursor: "pointer", flex: 1,
                          display: "flex",
                          padding: 20,
                          marginRight: 12,
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          backgroundColor: "#39998e"
                      }}>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.7,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Box style={{cursor: "pointer", padding: 10}}>
                                  <CurriculumSvg/>
                              </Box>
                              <Box>
                                  <Typography
                                      textAlign={"right"}
                                      style={{cursor: "pointer", lineHeight: "25px"}}
                                      fontWeight={600}
                                      fontSize={25}
                                      color={"#fff"}
                                  >
                                      {curriculumCount?.data ?? 0}
                                  </Typography>
                              </Box>
                          </Box>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.3,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Typography
                                  align={"center"}
                                  fontSize={12}
                                  color={"#fff"}
                                  textTransform="uppercase"
                              >
                                  No. of Curriculums
                              </Typography>
                          </Box>

                      </Card>

                      <Card onClick={() => {

                          history.push("settings?page=2")

                      }} style={{
                          cursor: "pointer", flex: 1,
                          display: "flex",
                          padding: 20,
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          backgroundColor: "#da674a"
                      }}>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.7,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Box style={{cursor: "pointer", padding: 10}}>
                                  <CourseSvg/>
                              </Box>
                              <Box>
                                  <Typography
                                      textAlign={"right"}
                                      style={{cursor: "pointer", lineHeight: "25px"}}
                                      fontWeight={600}
                                      fontSize={25}
                                      color={"#fff"}
                                  >
                                      {courseCount?.data ?? 0}
                                  </Typography>
                              </Box>
                          </Box>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.3,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Typography
                                  align={"center"}
                                  fontSize={12}
                                  color={"#fff"}
                                  textTransform="uppercase"
                              >
                                  No. of Courses
                              </Typography>
                          </Box>

                      </Card>

                  </div>


                  <div
                      style={{
                          paddingTop: "10px",
                          cursor: "pointer",
                          display: "flex",
                          flex: 0.4,
                      }}
                  >
                      <Card onClick={() => {

                          history.push("settings?page=1&filterBy=approved")

                      }} style={{
                          cursor: "pointer", flex: 1,
                          display: "flex",
                          padding: 20,
                          marginRight: 12,
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          backgroundColor: "#2c6975"
                      }}>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.7,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Box style={{cursor: "pointer", padding: 10}}>
                                  <ProgramSvg/>
                              </Box>
                              <Box>
                                  <Typography
                                      textAlign={"right"}
                                      style={{cursor: "pointer", lineHeight: "25px"}}
                                      fontWeight={600}
                                      fontSize={25}
                                      color={"#fff"}
                                  >
                                      {programApprovedCount?.data ?? 0}
                                  </Typography>
                              </Box>
                          </Box>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.3,
                                  display: "flex",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Typography
                                  fontSize={12}
                                  color={"#fff"}
                                  align={"center"}
                                  textTransform="uppercase"
                              >
                                  NO. OF PROGRAMS Approved
                              </Typography>
                          </Box>

                      </Card>

                      <Card onClick={() => {

                          history.push("settings?page=1&filterBy=draft")

                      }} style={{
                          cursor: "pointer", flex: 1,
                          display: "flex",
                          padding: 20,
                          marginRight: 12,
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          backgroundColor: "#fa9284"
                      }}>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.7,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Box style={{cursor: "pointer", padding: 10}}>
                                  <CurriculumSvg/>
                              </Box>
                              <Box>
                                  <Typography
                                      textAlign={"right"}
                                      style={{cursor: "pointer", lineHeight: "25px"}}
                                      fontWeight={600}
                                      fontSize={25}
                                      color={"#fff"}
                                  >
                                      {programDraftCount?.data ?? 0}
                                  </Typography>
                              </Box>
                          </Box>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.3,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Typography
                                  align={"center"}
                                  fontSize={12}
                                  color={"#fff"}
                                  textTransform="uppercase"
                              >
                                  NO. OF PROGRAMS Draft
                              </Typography>
                          </Box>

                      </Card>

                      <Card onClick={() => {

                          history.push("settings?page=1&filterBy=submitted")

                      }} style={{
                          cursor: "pointer", flex: 1,
                          display: "flex",
                          padding: 20,
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          backgroundColor: "#5874dc"
                      }}>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.7,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Box style={{cursor: "pointer", padding: 10}}>
                                  <CourseSvg/>
                              </Box>
                              <Box>
                                  <Typography
                                      textAlign={"right"}
                                      style={{cursor: "pointer", lineHeight: "25px"}}
                                      fontWeight={600}
                                      fontSize={25}
                                      color={"#fff"}
                                  >
                                      {programSubmittedCount?.data ?? 0}
                                  </Typography>
                              </Box>
                          </Box>
                          <Box
                              style={{
                                  cursor: "pointer",
                                  flex: 0.3,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Typography
                                  align={"center"}
                                  fontSize={12}
                                  color={"#fff"}
                                  textTransform="uppercase"
                              >
                                  NO. OF PROGRAMS Submitted
                              </Typography>
                          </Box>

                      </Card>

                  </div>
              </div>
          </Box>
        </>
      ),
        User: (
            <>
                <Box style={{cursor: "pointer", display: "flex", flex: 1}}>
                    <Box
                        boxShadow={1}
                        style={{
                            justifyContent: "space-between",
                            backgroundColor: "#fff",
                            display: "flex",
                            flex: 0.7,
                            cursor: "pointer",
                            paddingRight: 10,
                            paddingLeft: 10
                        }}
                    >

                        {data ? (
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            backgroundColor: "#fff",
                                        }}
                                    >
                                        <Box
                                            style={{
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >

                                            <Typography variant="overline" fontSize={20}>
                                                Statistics
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                cursor: "pointer",
                                                flex: 1,
                                                justifyContent: "center",
                                                padding: 10,
                                                flexDirection: "row",
                                                display: "flex",
                                            }}
                                        >
                                            <AppPieChart
                                                noContent={+graduatesCount + enrolleesCount}
                                                loading={countLoaded}
                                                title=""
                                                chartData={[
                                                    {label: 'Graduates', value: +graduatesCount},
                                                    {label: 'Enrollees', value: enrolleesCount},
                                                ]}
                                                chartColors={[
                                                    "#CE2028",
                                                    "#034F8B",
                                                ]}/>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>
                        ) : null}


                    </Box>
                    <div
                        style={{
                            cursor: "pointer",
                            paddingLeft: 10,
                            flexDirection: "column",
                            display: "flex",
                            flex: 0.3,
                        }}
                    >
                        <Card onClick={() => {

                            history.push("/students?status=complete")

                        }} style={{
                            cursor: "pointer", height: "50%", flex: 1,
                            display: "flex", alignItems: "center", backgroundColor: "#CE2028", marginBottom: 10
                        }}>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    padding: 30,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{cursor: "pointer", padding: 10}}>
                                    <GraduateIcon/>
                                </Box>

                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{cursor: "pointer", lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {+graduatesCount}
                                    </Typography>

                                    <Typography
                                        fontSize={12}
                                        color={"#fff"}
                                        textTransform="uppercase"
                                    >
                                        No. of Graduates
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                        <Card onClick={() => {

                            history.push("/students?status=incomplete")

                        }} style={{
                            cursor: "pointer", height: "50%", flex: 1,
                            display: "flex", alignItems: "center", backgroundColor: "#034F8B"
                        }}>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    padding: 30,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{cursor: "pointer", padding: 10}}>
                                    <EnrollmentIcon/>
                                </Box>

                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{cursor: "pointer", lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {enrolleesCount}
                                    </Typography>

                                    <Typography
                                        fontSize={12}
                                        color={"#fff"}
                                        textTransform="uppercase"
                                    >
                                        No. of Enrollees
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    </div>

                </Box>
            </>
        ),
        SuperAdmin: (
            <>
                <Box style={{display: "flex", flex: 1}}>
                    <Box
                        boxShadow={1}
                        style={{
                            cursor: "pointer",
                            justifyContent: "center",
                            backgroundColor: "#fff",
                            display: "flex",
                            flex: 0.7,
                        }}
                    >
                        <Box
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                justifyContent: "space-between",
                                backgroundColor: "#fff",
                            }}
                        >
                            <Box
                                style={{
                                    cursor: "pointer",
                                    paddingTop: 20,
                                    paddingLeft: 20,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography fontWeight={"bold"} variant="overline" fontSize={20}>
                                    Statistics
                                </Typography>

                            </Box>
                            <div style={{flex: 1, display: "flex",}}>
                                <ResponsiveContainer
                                    key={`rc_${lineCount}${data.length}1`}
                                    width="50%"
                                    height={300}
                                >
                                    <LineChart
                                        key={`rc_${lineCount}${data.length}1`}
                                        data={data}
                                        margin={{top: 15, right: 0, bottom: 15, left: 0}}
                                    >
                                        <Tooltip key={`rc_${lineCount}${data.length}2`}/>
                                        <XAxis
                                            key={`rc_${lineCount}${data.length}3`}
                                            dataKey="label"
                                        />
                                        <YAxis key={`rc_${lineCount}${data.length}4`}/>
                                        <CartesianGrid
                                            key={`rc_${lineCount}${data.length}5`}
                                            stroke="#ccc"
                                            strokeDasharray="5 5"
                                        />
                                        <Legend key={`rc_${lineCount}${data.length}7`}/>
                                        <Line
                                            key={`rc_${lineCount}${data.length}8`}
                                            type="monotone"
                                            dataKey="PR"
                                            stroke="#28B05B"
                                        />
                                        <Line
                                            key={`rc_${lineCount}${data.length}9`}
                                            type="monotone"
                                            dataKey="Submitted"
                                            stroke="#36A2EB"
                                        />
                                        <Line
                                            key={`rc_${lineCount}${data.length}10`}
                                            type="monotone"
                                            dataKey="Pending"
                                            stroke="#FF4970"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                                <div style={{flex: 1, display: "flex"}}>

                                    <div style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        flex: 0.2,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}>
                                        <Card

                                            onClick={() => {

                                                history.push("/enrollments")

                                            }}
                                            style={{
                                                cursor: "pointer",
                                                minHeight: "95px",
                                                minWidth: "111px",
                                                padding: 20,
                                                margin: 10,
                                                display: "flex",
                                                backgroundColor: "#FAFAFA",
                                                flexDirection: "column",
                                                alignItems: "flex-end",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Box>
                                                <SubmittedIcon/>
                                            </Box>
                                            <Typography
                                                fontSize={10}
                                                style={{lineHeight: "10px"}}
                                                textTransform={"uppercase"}
                                            >
                                                Submitted
                                            </Typography>
                                            <Typography
                                                fontWeight={600}
                                                fontSize={18}
                                                style={{color: "#36A2EB", lineHeight: "27px"}}
                                            >
                                                {submitted}
                                            </Typography>
                                        </Card>
                                        <Card
                                            onClick={() => {

                                                history.push("/enrollments")

                                            }}
                                            style={{
                                                cursor: "pointer",
                                                minHeight: "95px",
                                                minWidth: "111px",
                                                padding: 20,
                                                margin: 10,
                                                display: "flex",
                                                backgroundColor: "#FAFAFA",
                                                flexDirection: "column",
                                                alignItems: "flex-end",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Box>
                                                <PendingIcon/>
                                            </Box>
                                            <Typography
                                                fontSize={10}
                                                style={{lineHeight: "18px"}}
                                                textTransform={"uppercase"}
                                            >
                                                Pending
                                            </Typography>
                                            <Typography
                                                fontWeight={600}
                                                fontSize={18}
                                                style={{color: "#FF4970", lineHeight: "27px"}}
                                            >
                                                {pending}
                                            </Typography>
                                        </Card>
                                    </div>

                                    <div style={{
                                        flex: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>

                                        <AppPieChart
                                            noContent={+graduatesCount + enrolleesCount}
                                            loading={countLoaded}
                                            title=""
                                            chartData={[
                                                // { label: 'Schools', value: schoolCount },
                                                {label: 'Graduates', value: +graduatesCount},
                                                {label: 'Enrollees', value: enrolleesCount},
                                            ]}
                                            chartColors={[
                                                //  "#F0C301",
                                                "#CE2028",
                                                "#034F8B",
                                            ]}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                paddingBottom: 12,
                                paddingTop: 12,
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                            }}>
                                <form onSubmit={(event) => event.preventDefault()}>
                                    <>
                                        <Autocomplete
                                            id={"dashboardschoolyear"}

                                            disabled={schoolYearIsLoading}
                                            disableClearable
                                            size="small"
                                            value={selectedSchoolYear}
                                            onChange={(event: any, newValue: string | null) => {
                                                setSelectedSchoolYear(newValue ? newValue : {});
                                            }}
                                            getOptionLabel={(option) => {
                                                return option.name
                                            }
                                            }
                                            renderOption={(props, option) => (
                                                <li {...props} id={"settingdashboard" + option.id} key={option.id}>
                                                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                        {option.name}</div>
                                                </li>
                                            )}
                                            options={[{
                                                name: "All",
                                                id: undefined
                                            }, ...(_.uniqWith(schoolYear?.map((c: any, index: number) => {
                                                return c
                                            }), function (arrVal: any, othVal: any) {
                                                return arrVal?.name === othVal?.name;
                                            }) || [])] || []}
                                            style={{width: 300, marginLeft: 6}}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Select Academic Year "/>
                                            )}
                                        />
                                    </>
                                </form>
                                <form onSubmit={(event) => event.preventDefault()}>
                                    <>
                                        <Autocomplete
                                            id={"dashboardschoolyearsemester"}
                                            disabled={schoolYearIsLoading}
                                            disableClearable
                                            size="small"
                                            value={selectedSemester}
                                            onChange={(event: any, newValue: string | null) => {
                                                setSelectedSemester(newValue ? newValue : {});
                                            }}
                                            getOptionLabel={(option) => {
                                                return option.name
                                            }
                                            }
                                            renderOption={(props, option) => (
                                                <li {...props} id={"settindashboardg" + option.id} key={option.id}>
                                                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                        {option.name}</div>
                                                </li>
                                            )}
                                            options={[...(semesterMemo || [])] || []}
                                            style={{width: 300, marginLeft: 6}}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Select Semester"/>
                                            )}
                                        />
                                    </>
                                </form>

                            </div>


                        </Box>


                    </Box>
                    <div
                        style={{
                            paddingLeft: 10,
                            flexDirection: "column",
                            display: "flex",
                            flex: 0.3,
                        }}
                    >
                        <Card onClick={() => {

                            history.push("/schools")

                        }} style={{cursor: "pointer", backgroundColor: "#F0C301", height: "50%", marginBottom: 10}}>

                            <Box
                                style={{
                                    cursor: "pointer",
                                    padding: 30,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{padding: 10}}>
                                    <ActiveSchool/>
                                </Box>

                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {schoolCount}
                                    </Typography>

                                    <Typography
                                        style={{cursor: "pointer", lineHeight: "15px"}}
                                        fontSize={12}
                                        color={"#fff"}
                                        textTransform="uppercase"
                                    >
                                        Active School
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                        <Card onClick={() => {

                            history.push("/students?status=complete")

                        }} style={{backgroundColor: "#CE2028", height: "50%", marginBottom: 10}}>
                            <Box
                                style={{
                                    padding: 30,
                                    flex: 1, cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{padding: 10}}>
                                    <GraduateIcon/>
                                </Box>

                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {+graduatesCount}
                                    </Typography>

                                    <Typography
                                        fontSize={12}
                                        color={"#fff"}
                                        textTransform="uppercase"
                                    >
                                        No. of Graduates
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                        <Card onClick={() => {

                            history.push("/students?status=complete")

                        }} style={{cursor: "pointer", backgroundColor: "#034F8B", height: "33%",}}>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    padding: 30,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{cursor: "pointer", padding: 10}}>
                                    <EnrollmentIcon/>
                                </Box>

                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{cursor: "pointer", lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {enrolleesCount}
                                    </Typography>

                                    <Typography
                                        fontSize={12}
                                        color={"#fff"}
                                        textTransform="uppercase"
                                    >
                                        No. of Enrollees
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    </div>
                    <div
                        style={{
                            paddingTop: "10px",
                            cursor: "pointer",
                            display: "flex",
                            flex: 0.4,
                        }}
                    >
                        <Card onClick={() => {

                            history.push("settings?page=1")

                        }} style={{
                            cursor: "pointer", flex: 1,
                            display: "flex",
                            padding: 20,
                            marginRight: 12,
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                            backgroundColor: "#2b4d59"
                        }}>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.7,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{cursor: "pointer", padding: 10}}>
                                    <ProgramSvg/>
                                </Box>
                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{cursor: "pointer", lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {programCount?.data ?? 0}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.3,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    fontSize={12}
                                    color={"#fff"}
                                    align={"center"}
                                    textTransform="uppercase"
                                >
                                    No. of Programs
                                </Typography>
                            </Box>

                        </Card>

                        <Card onClick={() => {

                            history.push("settings?page=curriculum")

                        }} style={{
                            cursor: "pointer", flex: 1,
                            display: "flex",
                            padding: 20,
                            marginRight: 12,
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                            backgroundColor: "#39998e"
                        }}>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.7,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{cursor: "pointer", padding: 10}}>
                                    <CurriculumSvg/>
                                </Box>
                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{cursor: "pointer", lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {curriculumCount?.data ?? 0}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.3,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    align={"center"}
                                    fontSize={12}
                                    color={"#fff"}
                                    textTransform="uppercase"
                                >
                                    No. of Curriculums
                                </Typography>
                            </Box>

                        </Card>

                        <Card onClick={() => {

                            history.push("settings?page=2")

                        }} style={{
                            cursor: "pointer", flex: 1,
                            display: "flex",
                            padding: 20,
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                            backgroundColor: "#da674a"
                        }}>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.7,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{cursor: "pointer", padding: 10}}>
                                    <CourseSvg/>
                                </Box>
                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{cursor: "pointer", lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {courseCount?.data ?? 0}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.3,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    align={"center"}
                                    fontSize={12}
                                    color={"#fff"}
                                    textTransform="uppercase"
                                >
                                    No. of Courses
                                </Typography>
                            </Box>

                        </Card>

                    </div>

                    <div
                        style={{
                            paddingTop: "10px",
                            cursor: "pointer",
                            display: "flex",
                            flex: 0.4,
                        }}
                    >
                        <Card onClick={() => {

                            history.push("settings?page=1&filterBy=approved")

                        }} style={{
                            cursor: "pointer", flex: 1,
                            display: "flex",
                            padding: 20,
                            marginRight: 12,
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                            backgroundColor: "#2c6975"
                        }}>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.7,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{cursor: "pointer", padding: 10}}>
                                    <ProgramSvg/>
                                </Box>
                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{cursor: "pointer", lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {programApprovedCount?.data ?? 0}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.3,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    fontSize={12}
                                    color={"#fff"}
                                    align={"center"}
                                    textTransform="uppercase"
                                >
                                    NO. OF PROGRAMS Approved
                                </Typography>
                            </Box>

                        </Card>

                        <Card onClick={() => {
                            history.push("settings?page=1&filterBy=draft")
                        }} style={{
                            cursor: "pointer", flex: 1,
                            display: "flex",
                            padding: 20,
                            marginRight: 12,
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                            backgroundColor: "#fa9284"
                        }}>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.7,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{cursor: "pointer", padding: 10}}>
                                    <CurriculumSvg/>
                                </Box>
                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{cursor: "pointer", lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {programDraftCount?.data ?? 0}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.3,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    align={"center"}
                                    fontSize={12}
                                    color={"#fff"}
                                    textTransform="uppercase"
                                >
                                    NO. OF PROGRAMS Draft
                                </Typography>
                            </Box>

                        </Card>

                        <Card onClick={() => {

                            history.push("settings?page=1&filterBy=submitted")

                        }} style={{
                            cursor: "pointer", flex: 1,
                            display: "flex",
                            padding: 20,
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                            backgroundColor: "#5874dc"
                        }}>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.7,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{cursor: "pointer", padding: 10}}>
                                    <CourseSvg/>
                                </Box>
                                <Box>
                                    <Typography
                                        textAlign={"right"}
                                        style={{cursor: "pointer", lineHeight: "25px"}}
                                        fontWeight={600}
                                        fontSize={25}
                                        color={"#fff"}
                                    >
                                        {programSubmittedCount?.data ?? 0}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    cursor: "pointer",
                                    flex: 0.3,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    align={"center"}
                                    fontSize={12}
                                    color={"#fff"}
                                    textTransform="uppercase"
                                >
                                    NO. OF PROGRAMS Submitted
                                </Typography>
                            </Box>

                        </Card>

                    </div>
                </Box>
            </>
        ),
    };
  }, [ userState?.roles, data, enrolleesCount, schoolCount, +graduatesCount]);


    const roleChed = useMemo(() => {
        return {
            Admin: (
                <>
                    <Box style={{display: "flex", flex: 1}}>
                        <Box
                            boxShadow={1}
                            style={{
                                cursor: "pointer",
                                justifyContent: "center",
                                backgroundColor: "#fff",
                                display: "flex",
                                flex: 0.7,
                            }}
                        >
                            <Box
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    backgroundColor: "#fff",
                                }}
                            >
                                <Box
                                    style={{
                                        cursor: "pointer",
                                        paddingTop: 20,
                                        paddingLeft: 20,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography fontWeight={"bold"} variant="overline" fontSize={20}>
                                        Statistics
                                    </Typography>

                                </Box>
                                <div style={{flex: 1, display: "flex",}}>
                                    <ResponsiveContainer
                                        key={`rc_${lineCount}${data.length}1`}
                                        width="50%"
                                        height={300}
                                    >
                                        <LineChart
                                            key={`rc_${lineCount}${data.length}1`}
                                            data={data}
                                            margin={{top: 15, right: 0, bottom: 15, left: 0}}
                                        >
                                            <Tooltip key={`rc_${lineCount}${data.length}2`}/>
                                            <XAxis
                                                key={`rc_${lineCount}${data.length}3`}
                                                dataKey="label"
                                            />
                                            <YAxis key={`rc_${lineCount}${data.length}4`}/>
                                            <CartesianGrid
                                                key={`rc_${lineCount}${data.length}5`}
                                                stroke="#ccc"
                                                strokeDasharray="5 5"
                                            />
                                            <Legend key={`rc_${lineCount}${data.length}7`}/>
                                            <Line
                                                key={`rc_${lineCount}${data.length}8`}
                                                type="monotone"
                                                dataKey="PR"
                                                stroke="#28B05B"
                                            />
                                            <Line
                                                key={`rc_${lineCount}${data.length}9`}
                                                type="monotone"
                                                dataKey="Submitted"
                                                stroke="#36A2EB"
                                            />
                                            <Line
                                                key={`rc_${lineCount}${data.length}10`}
                                                type="monotone"
                                                dataKey="Pending"
                                                stroke="#FF4970"
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                    <div style={{flex: 1, display: "flex"}}>

                                        <div style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flex: 0.2,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}>
                                            <Card

                                                onClick={() => {

                                                    history.push("/enrollments")

                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    minHeight: "95px",
                                                    minWidth: "111px",
                                                    padding: 20,
                                                    margin: 10,
                                                    display: "flex",
                                                    backgroundColor: "#FAFAFA",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Box>
                                                    <SubmittedIcon/>
                                                </Box>
                                                <Typography
                                                    fontSize={10}
                                                    style={{lineHeight: "10px"}}
                                                    textTransform={"uppercase"}
                                                >
                                                    Submitted
                                                </Typography>
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={18}
                                                    style={{color: "#6c88c4", lineHeight: "27px"}}
                                                >
                                                    {submitted}
                                                </Typography>
                                            </Card>
                                            <Card
                                                onClick={() => {

                                                    history.push("/enrollments")

                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    minHeight: "95px",
                                                    minWidth: "111px",
                                                    padding: 20,
                                                    margin: 10,
                                                    display: "flex",
                                                    backgroundColor: "#FAFAFA",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Box>
                                                    <PendingIcon/>
                                                </Box>
                                                <Typography
                                                    fontSize={10}
                                                    style={{lineHeight: "18px"}}
                                                    textTransform={"uppercase"}
                                                >
                                                    Pending
                                                </Typography>
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={18}
                                                    style={{color: "#FF4970", lineHeight: "27px"}}
                                                >
                                                    {pending}
                                                </Typography>
                                            </Card>
                                        </div>

                                        <div style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <AppPieChart
                                                noContent={+graduatesCount + enrolleesCount}
                                                loading={countLoaded}
                                                title=""
                                                chartData={[
                                                    // { label: 'Schools', value: schoolCount },
                                                    {label: 'Graduates', value: +graduatesCount},
                                                    {label: 'Enrollees', value: enrolleesCount},
                                                ]}
                                                chartColors={[
                                                    //  "#F0C301",
                                                    "#CE2028",
                                                    "#034F8B",
                                                ]}/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    paddingBottom: 12,
                                    paddingTop: 12,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                        <>
                                            <Autocomplete
                                                id={"dashboardschoolyearschoolyear"}
                                                disabled={schoolYearIsLoading}
                                                disableClearable
                                                size="small"
                                                value={selectedSchoolYear}
                                                onChange={(event: any, newValue: string | null) => {
                                                    setSelectedSchoolYear(newValue ? newValue : {});
                                                }}
                                                getOptionLabel={(option) => {
                                                    return option.name
                                                }
                                                }
                                                renderOption={(props, option) => (
                                                    <li {...props} id={"settidashboardng" + option.id} key={option.id}>
                                                        <div
                                                            style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                            {option.name}</div>
                                                    </li>
                                                )}
                                                options={[{
                                                    name: "All",
                                                    id: undefined
                                                }, ...(_.uniqWith(schoolYear?.map((c: any, index: number) => {
                                                    return c
                                                }), function (arrVal: any, othVal: any) {
                                                    return arrVal?.name === othVal?.name;
                                                }) || [])] || []}
                                                style={{width: 300, marginLeft: 6}}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Academic Year "/>
                                                )}
                                            />
                                        </>
                                    </form>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                        <>
                                            <Autocomplete
                                                id={"admindashboardschoolyear"}
                                                disabled={schoolYearIsLoading}
                                                disableClearable
                                                size="small"
                                                value={selectedSemester}
                                                onChange={(event: any, newValue: string | null) => {
                                                    setSelectedSemester(newValue ? newValue : {});
                                                }}
                                                getOptionLabel={(option) => {
                                                    return option.name
                                                }
                                                }
                                                renderOption={(props, option) => (
                                                    <li {...props} id={"dashboardsetting" + option.id} key={option.id}>
                                                        <div
                                                            style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                            {option.name}</div>
                                                    </li>
                                                )}
                                                options={[...(semesterMemo || [])] || []}
                                                style={{width: 300, marginLeft: 6}}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Semester"/>
                                                )}
                                            />
                                        </>
                                    </form>

                                </div>


                            </Box>


                        </Box>
                        <div
                            style={{
                                paddingLeft: 10,
                                flexDirection: "column",
                                display: "flex",
                                flex: 0.3,
                            }}
                        >
                            <Card onClick={() => {

                                history.push("/schools")

                            }} style={{cursor: "pointer", backgroundColor: "#F0C301", alignItems: "center",
                                flex: 1,
                                display: "flex",
                                height: "33%",
                                marginBottom: 10}}>

                                <Box
                                    style={{
                                        cursor: "pointer",
                                        padding: 30,
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box style={{padding: 10}}>
                                        <ActiveSchool/>
                                    </Box>

                                    <Box>
                                        <Typography
                                            textAlign={"right"}
                                            style={{lineHeight: "25px"}}
                                            fontWeight={600}
                                            fontSize={25}
                                            color={"#fff"}
                                        >
                                            {schoolCount}
                                        </Typography>

                                        <Typography
                                            style={{cursor: "pointer", lineHeight: "15px"}}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            Active School
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card onClick={() => {

                                history.push("/students?status=complete")

                            }} style={{
                                alignItems: "center",
                                flex: 1,
                                display: "flex",
                                backgroundColor: "#CE2028",
                                height: "33%",
                                marginBottom: 10
                            }}>
                                <Box
                                    style={{
                                        padding: 30,
                                        flex: 1, cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box style={{padding: 10}}>
                                        <GraduateIcon/>
                                    </Box>

                                    <Box>
                                        <Typography
                                            textAlign={"right"}
                                            style={{lineHeight: "25px"}}
                                            fontWeight={600}
                                            fontSize={25}
                                            color={"#fff"}
                                        >
                                            {+graduatesCount}
                                        </Typography>

                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Graduates
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card onClick={() => {

                                history.push("/students?status=incomplete")

                            }} style={{
                                alignItems: "center",
                                flex: 1,
                                display: "flex",
                                cursor: "pointer",
                                backgroundColor: "#034F8B",
                                height: "33%",
                            }}>
                                <Box
                                    style={{
                                        cursor: "pointer",
                                        padding: 30,
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box style={{cursor: "pointer", padding: 10}}>
                                        <EnrollmentIcon/>
                                    </Box>

                                    <Box>
                                        <Typography
                                            textAlign={"right"}
                                            style={{cursor: "pointer", lineHeight: "25px"}}
                                            fontWeight={600}
                                            fontSize={25}
                                            color={"#fff"}
                                        >
                                            {enrolleesCount}
                                        </Typography>

                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Enrollees
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <div
                                style={{
                                    paddingTop: "10px",
                                    cursor: "pointer",
                                    display: "flex",
                                    flex: 0.4,
                                }}
                            >
                                <Card onClick={() => {

                                    history.push("settings?page=1")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#2b4d59"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <ProgramSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            align={"center"}
                                            textTransform="uppercase"
                                        >
                                            No. of Program
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card onClick={() => {

                                    history.push("settings?page=curriculum")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#39998e"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CurriculumSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {curriculumCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Curriculums
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card onClick={() => {

                                    history.push("settings?page=2")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#da674a"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CourseSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {courseCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Courses
                                        </Typography>
                                    </Box>

                                </Card>

                            </div>

                            <div
                                style={{
                                    paddingTop: "10px",
                                    cursor: "pointer",
                                    display: "flex",
                                    flex: 0.4,
                                }}
                            >
                                <Card onClick={() => {

                                    history.push("settings?page=1&filterBy=approved")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#2c6975"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <ProgramSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programApprovedCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            align={"center"}
                                            textTransform="uppercase"
                                        >
                                            NO. OF PROGRAMS Approved
                                        </Typography>
                                    </Box>

                                </Card>


                                <Card onClick={() => {

                                    history.push("settings?page=1&filterBy=submitted")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#5874dc"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CourseSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programSubmittedCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            NO. OF PROGRAMS For Approval
                                        </Typography>
                                    </Box>

                                </Card>

                            </div>
                        </div>

                    </Box>
                </>
            ),
            User: (
                <>
                    <Box style={{display: "flex", flex: 1}}>
                        <Box
                            boxShadow={1}
                            style={{
                                cursor: "pointer",
                                justifyContent: "center",
                                backgroundColor: "#fff",
                                display: "flex",
                                flex: 0.7,
                            }}
                        >
                            <Box
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    backgroundColor: "#fff",
                                }}
                            >
                                <Box
                                    style={{
                                        cursor: "pointer",
                                        paddingTop: 20,
                                        paddingLeft: 20,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography fontWeight={"bold"} variant="overline" fontSize={20}>
                                        Statistics
                                    </Typography>

                                </Box>
                                <div style={{flex: 1, display: "flex",}}>
                                    <ResponsiveContainer
                                        key={`rc_${lineCount}${data.length}1`}
                                        width="50%"
                                        height={300}
                                    >
                                        <LineChart
                                            key={`rc_${lineCount}${data.length}1`}
                                            data={data}
                                            margin={{top: 15, right: 0, bottom: 15, left: 0}}
                                        >
                                            <Tooltip key={`rc_${lineCount}${data.length}2`}/>
                                            <XAxis
                                                key={`rc_${lineCount}${data.length}3`}
                                                dataKey="label"
                                            />
                                            <YAxis key={`rc_${lineCount}${data.length}4`}/>
                                            <CartesianGrid
                                                key={`rc_${lineCount}${data.length}5`}
                                                stroke="#ccc"
                                                strokeDasharray="5 5"
                                            />
                                            <Legend key={`rc_${lineCount}${data.length}7`}/>
                                            <Line
                                                key={`rc_${lineCount}${data.length}8`}
                                                type="monotone"
                                                dataKey="PR"
                                                stroke="#28B05B"
                                            />
                                            <Line
                                                key={`rc_${lineCount}${data.length}9`}
                                                type="monotone"
                                                dataKey="Submitted"
                                                stroke="#36A2EB"
                                            />
                                            <Line
                                                key={`rc_${lineCount}${data.length}10`}
                                                type="monotone"
                                                dataKey="Pending"
                                                stroke="#FF4970"
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                    <div style={{flex: 1, display: "flex"}}>

                                        <div style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flex: 0.2,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}>
                                            <Card

                                                onClick={() => {

                                                    history.push("/enrollments")

                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    minHeight: "95px",
                                                    minWidth: "111px",
                                                    padding: 20,
                                                    margin: 10,
                                                    display: "flex",
                                                    backgroundColor: "#FAFAFA",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Box>
                                                    <SubmittedIcon/>
                                                </Box>
                                                <Typography
                                                    fontSize={10}
                                                    style={{lineHeight: "10px"}}
                                                    textTransform={"uppercase"}
                                                >
                                                    Submitted
                                                </Typography>
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={18}
                                                    style={{color: "#36A2EB", lineHeight: "27px"}}
                                                >
                                                    {submitted}
                                                </Typography>
                                            </Card>
                                            <Card
                                                onClick={() => {

                                                    history.push("/enrollments")

                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    minHeight: "95px",
                                                    minWidth: "111px",
                                                    padding: 20,
                                                    margin: 10,
                                                    display: "flex",
                                                    backgroundColor: "#FAFAFA",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Box>
                                                    <PendingIcon/>
                                                </Box>
                                                <Typography
                                                    fontSize={10}
                                                    style={{lineHeight: "18px"}}
                                                    textTransform={"uppercase"}
                                                >
                                                    Pending
                                                </Typography>
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={18}
                                                    style={{color: "#FF4970", lineHeight: "27px"}}
                                                >
                                                    {pending}
                                                </Typography>
                                            </Card>
                                        </div>

                                        <div style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <AppPieChart
                                                noContent={+graduatesCount + enrolleesCount}
                                                loading={countLoaded}
                                                title=""
                                                chartData={[
                                                    // { label: 'Schools', value: schoolCount },
                                                    {label: 'Graduates', value: +graduatesCount},
                                                    {label: 'Enrollees', value: enrolleesCount},
                                                ]}
                                                chartColors={[
                                                    //  "#F0C301",
                                                    "#CE2028",
                                                    "#034F8B",
                                                ]}/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    paddingBottom: 12,
                                    paddingTop: 12,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                        <>
                                            <Autocomplete id={"userdashboardschoolyear"}
                                                          disabled={schoolYearIsLoading}
                                                          disableClearable
                                                          size="small"
                                                          value={selectedSchoolYear}
                                                          onChange={(event: any, newValue: string | null) => {
                                                              setSelectedSchoolYear(newValue ? newValue : {});
                                                          }}
                                                          getOptionLabel={(option) => {
                                                              return option.name
                                                          }
                                                          }
                                                          renderOption={(props, option) => (
                                                              <li {...props} id={"settingschoolyear" + option.id}
                                                                  key={option.id}>
                                                                  <div style={{
                                                                      flex: 1,
                                                                      display: "flex",
                                                                      flexDirection: "column"
                                                                  }}>
                                                                      {option.name}</div>
                                                              </li>
                                                          )}
                                                          options={[{
                                                              name: "All",
                                                              id: undefined
                                                          }, ...(_.uniqWith(schoolYear?.map((c: any, index: number) => {
                                                              return c
                                                          }), function (arrVal: any, othVal: any) {
                                                              return arrVal?.name === othVal?.name;
                                                          }) || [])] || []}
                                                          style={{width: 300, marginLeft: 6}}
                                                          renderInput={(params) => (
                                                              <TextField {...params} label="Select Academic Year "/>
                                                          )}
                                            />
                                        </>
                                    </form>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                        <>
                                            <Autocomplete id={"cheddashboardschoolyear"}
                                                          disabled={schoolYearIsLoading}
                                                          disableClearable
                                                          size="small"
                                                          value={selectedSemester}
                                                          onChange={(event: any, newValue: string | null) => {
                                                              setSelectedSemester(newValue ? newValue : {});
                                                          }}
                                                          getOptionLabel={(option) => {
                                                              return option.name
                                                          }
                                                          }
                                                          renderOption={(props, option) => (
                                                              <li {...props} id={"setsemesterting" + option.id}
                                                                  key={option.id}>
                                                                  <div style={{
                                                                      flex: 1,
                                                                      display: "flex",
                                                                      flexDirection: "column"
                                                                  }}>
                                                                      {option.name}</div>
                                                              </li>
                                                          )}
                                                          options={[...(semesterMemo || [])] || []}
                                                          style={{width: 300, marginLeft: 6}}
                                                          renderInput={(params) => (
                                                              <TextField {...params} label="Select Semester"/>
                                                          )}
                                            />
                                        </>
                                    </form>

                                </div>


                            </Box>


                        </Box>
                        <div
                            style={{
                                paddingLeft: 10,
                                flexDirection: "column",
                                display: "flex",
                                flex: 0.3,
                            }}
                        >
                            <Card onClick={() => {

                                history.push("/schools")

                            }} style={{cursor: "pointer", backgroundColor: "#F0C301", alignItems: "center",
                                flex: 1,
                                display: "flex",
                                height: "50%",
                                marginBottom: 10}}>

                                <Box
                                    style={{
                                        cursor: "pointer",
                                        padding: 30,
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box style={{padding: 10}}>
                                        <ActiveSchool/>
                                    </Box>

                                    <Box>
                                        <Typography
                                            textAlign={"right"}
                                            style={{lineHeight: "25px"}}
                                            fontWeight={600}
                                            fontSize={25}
                                            color={"#fff"}
                                        >
                                            {schoolCount}
                                        </Typography>

                                        <Typography
                                            style={{cursor: "pointer", lineHeight: "15px"}}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            Active School
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card onClick={() => {

                                history.push("/students?status=complete")

                            }} style={{
                                alignItems: "center",
                                flex: 1,
                                cursor: "pointer",
                                display: "flex",
                                backgroundColor: "#CE2028",
                                height: "50%",
                                marginBottom: 10
                            }}>
                                <Box
                                    style={{
                                        padding: 30,
                                        flex: 1, cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box style={{padding: 10}}>
                                        <GraduateIcon/>
                                    </Box>

                                    <Box>
                                        <Typography
                                            textAlign={"right"}
                                            style={{lineHeight: "25px"}}
                                            fontWeight={600}
                                            fontSize={25}
                                            color={"#fff"}
                                        >
                                            {+graduatesCount}
                                        </Typography>

                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Graduates
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card onClick={() => {

                                history.push("/students?status=incomplete")

                            }} style={{
                                alignItems: "center",
                                cursor: "pointer",
                                flex: 1,
                                display: "flex",
                                backgroundColor: "#034F8B",
                                height: "50%",
                            }}>
                                <Box
                                    style={{
                                        cursor: "pointer",
                                        padding: 30,
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box style={{cursor: "pointer", padding: 10}}>
                                        <EnrollmentIcon/>
                                    </Box>

                                    <Box>
                                        <Typography
                                            textAlign={"right"}
                                            style={{cursor: "pointer", lineHeight: "25px"}}
                                            fontWeight={600}
                                            fontSize={25}
                                            color={"#fff"}
                                        >
                                            {enrolleesCount}
                                        </Typography>

                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Enrollees
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <div
                                style={{
                                    paddingTop: "10px",
                                    cursor: "pointer",
                                    display: "flex",
                                    flex: 0.4,
                                }}
                            >
                                <Card onClick={() => {

                                    history.push("settings?page=1")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#2b4d59"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <ProgramSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            align={"center"}
                                            textTransform="uppercase"
                                        >
                                            No. of Programs
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card onClick={() => {

                                    history.push("settings?page=curriculum")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#39998e"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CurriculumSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {curriculumCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Curriculums
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card onClick={() => {

                                    history.push("settings?page=2")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#da674a"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CourseSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {courseCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Courses
                                        </Typography>
                                    </Box>

                                </Card>

                            </div>

                            <div
                                style={{
                                    paddingTop: "10px",
                                    cursor: "pointer",
                                    display: "flex",
                                    flex: 0.4,
                                }}
                            >
                                <Card onClick={() => {

                                    history.push("settings?page=1&filterBy=approved")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#2c6975"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <ProgramSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programApprovedCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            align={"center"}
                                            textTransform="uppercase"
                                        >
                                            NO. OF PROGRAMS Approved
                                        </Typography>
                                    </Box>

                                </Card>


                                <Card onClick={() => {

                                    history.push("settings?page=1&filterBy=submitted")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#5874dc"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CourseSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programSubmittedCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            NO. OF PROGRAMS Submitted
                                        </Typography>
                                    </Box>

                                </Card>

                            </div>
                        </div>

                    </Box>
                </>
            ),
            SuperAdmin: (
                <>
                    <Box style={{display: "flex", flex: 1}}>
                        <Box
                            boxShadow={1}
                            style={{
                                cursor: "pointer",
                                justifyContent: "center",
                                backgroundColor: "#fff",
                                display: "flex",
                                flex: 0.7,
                            }}
                        >
                            <Box
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    backgroundColor: "#fff",
                                }}
                            >
                                <Box
                                    style={{
                                        cursor: "pointer",
                                        paddingTop: 20,
                                        paddingLeft: 20,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography fontWeight={"bold"} variant="overline" fontSize={20}>
                                        Statistics
                                    </Typography>

                                </Box>
                                <div style={{flex: 1, display: "flex",}}>
                                    <ResponsiveContainer
                                        key={`rc_${lineCount}${data.length}1`}
                                        width="50%"
                                        height={300}
                                    >
                                        <LineChart
                                            key={`rc_${lineCount}${data.length}1`}
                                            data={data}
                                            margin={{top: 15, right: 0, bottom: 15, left: 0}}
                                        >
                                            <Tooltip key={`rc_${lineCount}${data.length}2`}/>
                                            <XAxis
                                                key={`rc_${lineCount}${data.length}3`}
                                                dataKey="label"
                                            />
                                            <YAxis key={`rc_${lineCount}${data.length}4`}/>
                                            <CartesianGrid
                                                key={`rc_${lineCount}${data.length}5`}
                                                stroke="#ccc"
                                                strokeDasharray="5 5"
                                            />
                                            <Legend key={`rc_${lineCount}${data.length}7`}/>
                                            <Line
                                                key={`rc_${lineCount}${data.length}8`}
                                                type="monotone"
                                                dataKey="PR"
                                                stroke="#28B05B"
                                            />
                                            <Line
                                                key={`rc_${lineCount}${data.length}9`}
                                                type="monotone"
                                                dataKey="Submitted"
                                                stroke="#36A2EB"
                                            />
                                            <Line
                                                key={`rc_${lineCount}${data.length}10`}
                                                type="monotone"
                                                dataKey="Pending"
                                                stroke="#FF4970"
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                    <div style={{flex: 1, display: "flex"}}>

                                        <div style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flex: 0.2,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}>
                                            <Card

                                                onClick={() => {

                                                    history.push("/enrollments")

                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    minHeight: "95px",
                                                    minWidth: "111px",
                                                    padding: 20,
                                                    margin: 10,
                                                    display: "flex",
                                                    backgroundColor: "#FAFAFA",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Box>
                                                    <SubmittedIcon/>
                                                </Box>
                                                <Typography
                                                    fontSize={10}
                                                    style={{lineHeight: "10px"}}
                                                    textTransform={"uppercase"}
                                                >
                                                    Submitted
                                                </Typography>
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={18}
                                                    style={{color: "#da674a", lineHeight: "27px"}}
                                                >
                                                    {submitted}
                                                </Typography>
                                            </Card>
                                            <Card
                                                onClick={() => {

                                                    history.push("/enrollments")

                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    minHeight: "95px",
                                                    minWidth: "111px",
                                                    padding: 20,
                                                    margin: 10,
                                                    display: "flex",
                                                    backgroundColor: "#FAFAFA",
                                                    flexDirection: "column",
                                                    alignItems: "flex-end",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Box>
                                                    <PendingIcon/>
                                                </Box>
                                                <Typography
                                                    fontSize={10}
                                                    style={{lineHeight: "18px"}}
                                                    textTransform={"uppercase"}
                                                >
                                                    Pending
                                                </Typography>
                                                <Typography
                                                    fontWeight={600}
                                                    fontSize={18}
                                                    style={{color: "#FF4970", lineHeight: "27px"}}
                                                >
                                                    {pending}
                                                </Typography>
                                            </Card>
                                        </div>

                                        <div style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <AppPieChart
                                                noContent={+graduatesCount + enrolleesCount}
                                                loading={countLoaded}
                                                title=""
                                                chartData={[
                                                    // { label: 'Schools', value: schoolCount },
                                                    {label: 'Graduates', value: +graduatesCount},
                                                    {label: 'Enrollees', value: enrolleesCount},
                                                ]}
                                                chartColors={[
                                                    //  "#F0C301",
                                                    "#CE2028",
                                                    "#034F8B",
                                                ]}/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    paddingBottom: 12,
                                    paddingTop: 12,
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                        <>
                                            <Autocomplete id={"chedadmindashboardschoolyear"}
                                                          disabled={schoolYearIsLoading}
                                                          disableClearable
                                                          size="small"
                                                          value={selectedSchoolYear}
                                                          onChange={(event: any, newValue: string | null) => {
                                                              setSelectedSchoolYear(newValue ? newValue : {});
                                                          }}
                                                          getOptionLabel={(option) => {
                                                              return option.name
                                                          }
                                                          }
                                                          renderOption={(props, option) => (
                                                              <li {...props} id={"schoolyearseldctsetting" + option.id}
                                                                  key={option.id}>
                                                                  <div style={{
                                                                      flex: 1,
                                                                      display: "flex",
                                                                      flexDirection: "column"
                                                                  }}>
                                                                      {option.name}</div>
                                                              </li>
                                                          )}
                                                          options={[{
                                                              name: "All",
                                                              id: undefined
                                                          }, ...(_.uniqWith(schoolYear?.map((c: any, index: number) => {
                                                              return c
                                                          }), function (arrVal: any, othVal: any) {
                                                              return arrVal?.name === othVal?.name;
                                                          }) || [])] || []}
                                                          style={{width: 300, marginLeft: 6}}
                                                          renderInput={(params) => (
                                                              <TextField {...params} label="Select Academic Year "/>
                                                          )}
                                            />
                                        </>
                                    </form>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                        <>
                                            <Autocomplete id={"cheduserdashboardschoolyear"}
                                                          disabled={schoolYearIsLoading}
                                                          disableClearable
                                                          size="small"
                                                          value={selectedSemester}
                                                          onChange={(event: any, newValue: string | null) => {
                                                              setSelectedSemester(newValue ? newValue : {});
                                                          }}
                                                          getOptionLabel={(option) => {
                                                              return option.name
                                                          }
                                                          }
                                                          renderOption={(props, option) => (
                                                              <li {...props} id={"sdasordetting" + option.id}
                                                                  key={option.id}>
                                                                  <div style={{
                                                                      flex: 1,
                                                                      display: "flex",
                                                                      flexDirection: "column"
                                                                  }}>
                                                                      {option.name}</div>
                                                              </li>
                                                          )}
                                                          options={[...(semesterMemo || [])] || []}
                                                          style={{width: 300, marginLeft: 6}}
                                                          renderInput={(params) => (
                                                              <TextField {...params} label="Select Semester"/>
                                                          )}
                                            />
                                        </>
                                    </form>

                                </div>


                            </Box>


                        </Box>
                        <div
                            style={{
                                paddingLeft: 10,
                                flexDirection: "column",
                                display: "flex",
                                flex: 0.3,
                            }}
                        >
                            <Card onClick={() => {

                                history.push("/schools")

                            }} style={{cursor: "pointer", backgroundColor: "#F0C301", height: "33%", marginBottom: 10}}>

                                <Box
                                    style={{
                                        cursor: "pointer",
                                        padding: 30,
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box style={{padding: 10}}>
                                        <ActiveSchool/>
                                    </Box>

                                    <Box>
                                        <Typography
                                            textAlign={"right"}
                                            style={{lineHeight: "25px"}}
                                            fontWeight={600}
                                            fontSize={25}
                                            color={"#fff"}
                                        >
                                            {schoolCount}
                                        </Typography>

                                        <Typography
                                            style={{cursor: "pointer", lineHeight: "15px"}}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            Active School
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card onClick={() => {

                                history.push("/students?status=complete")

                            }} style={{backgroundColor: "#CE2028", height: "33%", marginBottom: 10}}>
                                <Box
                                    style={{
                                        padding: 30,
                                        flex: 1, cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box style={{padding: 10}}>
                                        <GraduateIcon/>
                                    </Box>

                                    <Box>
                                        <Typography
                                            textAlign={"right"}
                                            style={{lineHeight: "25px"}}
                                            fontWeight={600}
                                            fontSize={25}
                                            color={"#fff"}
                                        >
                                            {+graduatesCount}
                                        </Typography>

                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Graduates
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card onClick={() => {

                                history.push("/students?status=incomplete")

                            }} style={{cursor: "pointer", backgroundColor: "#034F8B", height: "33%",}}>
                                <Box
                                    style={{
                                        cursor: "pointer",
                                        padding: 30,
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box style={{cursor: "pointer", padding: 10}}>
                                        <EnrollmentIcon/>
                                    </Box>

                                    <Box>
                                        <Typography
                                            textAlign={"right"}
                                            style={{cursor: "pointer", lineHeight: "25px"}}
                                            fontWeight={600}
                                            fontSize={25}
                                            color={"#fff"}
                                        >
                                            {enrolleesCount}
                                        </Typography>

                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Enrollees
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <div
                                style={{
                                    paddingTop: "10px",
                                    cursor: "pointer",
                                    display: "flex",
                                    flex: 0.4,
                                }}
                            >
                                <Card onClick={() => {

                                    history.push("settings?page=1")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#2b4d59"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <ProgramSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            align={"center"}
                                            textTransform="uppercase"
                                        >
                                            No. of Programs
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card onClick={() => {

                                    history.push("settings?page=curriculum")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#39998e"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CurriculumSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {curriculumCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Curriculums
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card onClick={() => {

                                    history.push("settings?page=2")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#da674a"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CourseSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {courseCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            No. of Courses
                                        </Typography>
                                    </Box>

                                </Card>

                            </div>

                            <div
                                style={{
                                    paddingTop: "10px",
                                    cursor: "pointer",
                                    display: "flex",
                                    flex: 0.4,
                                }}
                            >
                                <Card onClick={() => {

                                    history.push("settings?page=1&filterBy=approved")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#2c6975"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <ProgramSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programApprovedCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            fontSize={12}
                                            color={"#fff"}
                                            align={"center"}
                                            textTransform="uppercase"
                                        >
                                            NO. OF PROGRAMS Approved
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card onClick={() => {

                                    history.push("settings?page=1&filterBy=draft")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    marginRight: 12,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#fa9284"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CurriculumSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programDraftCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            NO. OF PROGRAMS Draft
                                        </Typography>
                                    </Box>

                                </Card>

                                <Card onClick={() => {

                                    history.push("settings?page=1&filterBy=submitted")

                                }} style={{
                                    cursor: "pointer", flex: 1,
                                    display: "flex",
                                    padding: 20,
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    backgroundColor: "#5874dc"
                                }}>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.7,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box style={{cursor: "pointer", padding: 10}}>
                                            <CourseSvg/>
                                        </Box>
                                        <Box>
                                            <Typography
                                                textAlign={"right"}
                                                style={{cursor: "pointer", lineHeight: "25px"}}
                                                fontWeight={600}
                                                fontSize={25}
                                                color={"#fff"}
                                            >
                                                {programSubmittedCount?.data ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        style={{
                                            cursor: "pointer",
                                            flex: 0.3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            align={"center"}
                                            fontSize={12}
                                            color={"#fff"}
                                            textTransform="uppercase"
                                        >
                                            NO. OF PROGRAMS Submitted
                                        </Typography>
                                    </Box>

                                </Card>

                            </div>
                        </div>
                    </Box>
                </>
            ),
        };
    }, [userState?.roles, data, enrolleesCount, schoolCount, +graduatesCount]);


    const type = useSelector((store: RootState) => store.auth?.user?.type)


    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>

            <div style={{height: "60px"}}></div>
            <div
                style={{
                    paddingRight: "40px",
                    paddingLeft: "40px",
                    marginBottom: 30,
                    alignItems: "center",
                    backgroundColor: "#024275",
                    height: "125px",
                    display: "flex",
                    flex: 1,
                }}
            >
                <Typography fontWeight={600} style={{color: "white"}}>
                    DASHBOARD
                </Typography>
            </div>

            <Box
                component="main"
                sx={{flexGrow: 1, pt: 10}}
                style={{padding: "20px"}}
            >
                {
                    (isCountLoaded || isChartDataLoaded) ? <Grid container wrap="nowrap">
                        <Grid item xs={8}>
                            <Item>
                                <Skeleton variant="rectangular" width={"100%"} height={430}/>
                            </Item>
                        </Grid>
                        <Grid ml={1} item xs={4}>
                            <Item sx={{marginBottom: 1}}>
                                <Skeleton variant="rectangular" width={"100%"} height={118}/>
                            </Item>
                            <Item sx={{marginBottom: 1}}>
                                <Skeleton variant="rectangular" width={"100%"} height={118}/>
                            </Item>
                            <Item sx={{marginBottom: 1}}>
                                <Skeleton variant="rectangular" width={"100%"} height={118}/>
                            </Item>
                        </Grid>
                    </Grid> : (type?.name == "CHED" || type?.name == "NATIONAL") ? roleChed[userState?.roles as keyof typeof roleChed] : role[userState?.roles as keyof typeof role]
                }


            </Box>
            {WarningComponent}
            {ErrorComponent}
            {UploadDialog}
        </>
    );
};

export default DashboarPage;
const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));
