import {AddRounded, DeleteRounded, FilterAltRounded, Lock, LockOpen, ViewColumnRounded,} from "@mui/icons-material";
import {
  Badge,
  Box, Button,
  Chip, CircularProgress,
  FormControl,
  IconButton, MenuItem, Select, SelectChangeEvent,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import flat from "flat";
import moment from 'moment-timezone';
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions, TableFilterList, TableToolbar,} from "mui-datatables";
import React, {FC, ReactNode, useEffect} from "react";
import {useQueryClient} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import useDialog from "../../hooks/useDialog";
import {usePromotionalReportQuery} from "../../hooks/usePromotionalReportQuery";
import {setLoading} from "../../redux/features/app";
import {
  deletePromotionalReport,
  getPromotionalReportById,
  postPromotionalReportsChangeStatus,
} from "../../requests/promotionalReport.request";
import {EnrollmentType, PromotionalReportType} from "../../types";
import StudentInfoDialog from "../dialogs/StudentInfo.dialog";
import {useDelete} from "../../hooks/useDelete";
import {useIsLocked} from "../../hooks/useIsLocked";
import {RootState, useAppSelector} from "../../redux/store";
import {useDebounce} from "../../hooks/useDebounce";
import {CircleDot, DotIcon, LucideFileText, UsersIcon} from "lucide-react";
import InputLabel from "@mui/material/InputLabel";

interface IEnrollmentTableProps {
  title: string;
  type: string;
}


const PromotionalReportTable: FC<IEnrollmentTableProps> = ({ type: typePR, title }) => {
  const dispatch = useDispatch();
  const type = useSelector((store: RootState) => store.auth?.user?.type)
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const roles = useAppSelector((store) => store?.auth?.user?.roles) as
      | "User"
      | "Admin"
      | "SuperAdmin";
  const [dropDownStatus, setDropDownStatus] = React.useState('');
  const [searchStr, setSearchStr] = React.useState('')
  const debouncedSearchStr = useDebounce(searchStr, 500)
  const [page, setPage] = React.useState(0);
  const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  function columns(data:
                       | {
                     _data: PromotionalReportType;
                     address: string;
                     lastModifiedDate: string;
                     lastModifiedBy: string;
                     approvedBy: string;
                     uuid: string;
                     enrollments: EnrollmentType[];
                     dateCreated: string;
                     preparedBy: string;
                     createdBy: string;
                     schoolInstitutionalCode: string;
                     schoolYear: string;
                     semester: string;
                     totalNoOfStudent: string;
                     id: number;
                     schoolName: string;
                   }[]
                       | undefined,
                   handleDelete: (e: React.SyntheticEvent<HTMLButtonElement>, schoolYear: any, customMessage?: any) => void,
                   handleIsLocked: (
                       e: React.SyntheticEvent<HTMLButtonElement>,
                       isLocked: any,
                       name?:any
                   ) => void,
                   roles: any): MUIDataTableColumn[] {
    var _columns:MUIDataTableColumn[] =  [
      {
        name: "id",
        label: "ID",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          setCellProps: (cellValue) => ({ style: { textAlign: "center" } }),
          display: false,
          filter: false,
          sort: true,
        },
      },
      {
        name: "schoolYear",
        label: "ACADEMIC YEAR",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          setCellProps: (cellValue) => ({ style: { textAlign: "center" } }),
          filter: true,
          sort: true,
        },
      },
      {
        name: "semester",
        label: "SEMESTER",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          setCellProps: (cellValue) => ({ style: { textAlign: "center" } }),
          filter: true,
          sort: true,
        },
      },
      {
        name: "schoolName",
        label: "SCHOOL NAME",

        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          setCellProps: (cellValue) => ({ style: { textAlign: "center" } }),
          filter: true,

          sort: true,
        },
      },
      {
        name: "totalNoOfEnrollment",
        label: "Number of Enrollees".toUpperCase(),

        options: {
          customHeadRender: ({ index, ...column }) => {

            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          setCellProps: (cellValue) => ({ style: { textAlign: "center" } }),
          filter: false,
          sort: true,
        },
      },
      {
        name: "unsuccessful",
        label: "Unsuccessful No of student".toUpperCase(),

        options: {
          display: !(type?.id == 2 && roles?.toLowerCase() == "admin"),
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            const table = tableMeta.rowData[tableMeta.rowData.length - 1];
            const _status = table.status;
            if (!value){
              return  <Badge key={value} variant="dot" color={_status == "PROCESSING" ? "warning" : "success"}>
                <UsersIcon />
              </Badge>
            }
            return (
               <Badge key={value} badgeContent={(value as any) ?? 0} color="error">
                  <UsersIcon />
                </Badge>

            );
          },
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            )
          },

          setCellProps: (cellValue) => ({ style: { textAlign: "center" } }),
          filter: false,
          sort: true,
        },
      },
      {
        name: "preparedBy",
        label: "PREPARED BY",

        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          setCellProps: (cellValue) => ({ style: { textAlign: "center" } }),
          filter: false,
          sort: true,

          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return (
                <Typography variant="body2">{value.split(",").shift()}</Typography>
            );
          },
        },
      },
      {
        name: "status",
        label: "status",

        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          setCellProps: (cellValue) => ({ style: { textAlign: "center" } }),
          filter: false,
          sort: false,
          display: false
        },
      },
      {
        name: "isLocked",
        label: "Status".toUpperCase(),

        options: {
          filter: false,
          sort: true,
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            const table = tableMeta.rowData[tableMeta.rowData.length - 1];
            const isLocked = table.isLocked;
            return (
                <Box display="flex" flexDirection="column">
                  {isLocked ?  <Chip label={"Submitted".toUpperCase()} variant="outlined" color={"primary"} style={{color: "#36A2EB" }} /> :<Chip color={"error"} label={ "Pending".toUpperCase()} variant="outlined" style={{color:"#FF4970" }}/>}
                </Box>
            );
          },
        },
      },{
        name: "dateCreated",
        label: "DATE UPLOADED",

        options: {
          filter: true,
          sort: true,
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            return (
                <Box display="flex" flexDirection="column">
                  {/*<Typography textAlign={"left"} variant='subtitle2'>{moment.utc(value).tz('Asia/Manila').fromNow()}</Typography>*/}
                  <Typography variant='caption'>{moment.utc(value).tz('Asia/Manila').format('MMMM D, YYYY, h:mm a')}</Typography>
                </Box>
            );
          },
        },
      },
      {
        name: "id",
        label: "ACTION",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
                <TableCell align="center" key={column.label}>
                  {column.label}
                </TableCell>
            );
          },
          display: ["admin", "superadmin"]?.indexOf(roles?.toLowerCase()) > -1 || (type?.id == 2 && roles == "User"),
          filter: false,
          sort: false,
          viewColumns: false,
          customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
            const table = tableMeta.rowData[tableMeta.rowData.length - 1];
            const schoolYear =
                tableMeta.rowData[
                    _columns.findIndex((col) => col.name === "schoolYear")
                    ];

            const semester =
                tableMeta.rowData[
                    _columns.findIndex((col) => col.name === "semester")
                    ];
            const isLocked = table.isLocked;
            return (
                <Stack justifyContent={"center"} direction="row" spacing={1}>
                  {((["admin", "superadmin", "user"]?.indexOf(roles?.toLowerCase()) > -1 && type?.id == 2) || (["admin", "superadmin"]?.indexOf(roles?.toLowerCase()) > -1)) ? (
                      <Tooltip title={isLocked ? "locked" : "unlocked"} arrow>
                        <IconButton
                            id={"icon-locked-pr"}
                            aria-label={"icon-locked-pr"}
                            color={isLocked ? "error" : "info"}
                            size="small"
                            disabled={roles?.toLowerCase() == "admin" && type?.id == 1 && isLocked}
                            value={value}
                            onClick={(event) => handleIsLocked(event, isLocked, schoolYear + " " + semester)}
                        >
                          {isLocked ? <Lock /> : <LockOpen />}
                        </IconButton>
                      </Tooltip>
                  ) : null}

                  {!isLocked &&
                  ((["admin", "superadmin", "user"]?.indexOf(roles?.toLowerCase()) > -1 && type?.id == 2) || (["admin", "superadmin"]?.indexOf(roles?.toLowerCase()) > -1)) ? (
                      <Tooltip id={"tooltip-delete-pr"} title="Delete" arrow>
                        <IconButton
                            id={"icon-delete-pr"}
                            aria-label={"icon-delete-pr"}
                            color="error"
                            size="small"
                            value={value}
                            onClick={(e) => handleDelete(e, schoolYear + " " + semester,  (schoolYear + " " + semester + " is successfully deleted."))}
                        >

                          <DeleteRounded />
                        </IconButton>
                      </Tooltip>
                  ) : null}
                </Stack>
            );
          },
        },
      },
      {
        name: "_data", // put always to last
        label: "DATA",
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        },
      },
    ];
    return _columns
  }



  const { data: promotionalReports, isLoading } = usePromotionalReportQuery({
    pageSize: 100,
    search: debouncedSearchStr,
    ...(typePR && {header: typePR}),
    ...(page > -1 && {page: page + 1})
  });
  const {
    open: isOpenStudentInfo,
    handleClose: handleCloseStudentInfo,
    handleOpen: handleOpenStudentInfo,
    data: student,
    setData: setStudent,
  } = useDialog<EnrollmentType>();
  const queryClient = useQueryClient();
  const history = useHistory();
  const handleDelete = useDelete(
    "Delete Promotional Report",
    "promotional-report",
    deletePromotionalReport
  );
  const handleIsLocked = useIsLocked(
    dispatch,
    "Locked Promotional Report",
    "promotional-report",
    postPromotionalReportsChangeStatus
  );

  const onRowClick = async (id: string) => {
    dispatch(setLoading(true))
    const res = await getPromotionalReportById(id);
    const row = promotionalReports?.data?.find((pr) => +pr.id === +id);
    queryClient.setQueriesData("upload", (old: any) => {
      return {
        ...row,
        enrollments: res.data.map((el) => {
          return {
            ...el,
            ...(flat(el, { safe: true }) as object),
          };
        }),
        programs: res.programs,
      };
    });
    history.push(`/enrollments/${id}`, {
      enrollmentId: id,
      from: "/enrollments",
      title: `${row?.schoolName ?? ""} - ${row?.semester ?? ""} - ${row?.schoolYear ?? ""}`,
      uuid: row?.uuid,
    });
    dispatch(setLoading(false))
    // history.push("/promotional-report")
  };
  const options: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch:  <Box sx={{ textAlign: "center", py: 2 }}>
          {isLoading ? <CircularProgress size={24} /> : "No records found."}
        </Box>
      }
    },
    filterType: "dropdown",
    tableId: "promotionalreporttable",
    tableBodyMaxHeight: "600px",
    print: false,
    download: false,
    responsive: "simple",
    selectableRows: "none",
    onRowClick: (rowData) => {
      onRowClick(rowData[0]);
    },
    isRowExpandable: () => true,
    expandableRows: false,
    expandableRowsHeader: false,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const promotionalReportData = rowData[
        rowData.length - 1
      ] as unknown as PromotionalReportType;
      return (
        <TableRow>
          <TableCell
            colSpan={colSpan}
            sx={{
              px: 2,
            }}
          >
            <Typography>
              {" "}
              School name:{" "}
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                {promotionalReportData.schoolName}
              </Typography>
            </Typography>
            <Typography>
              {" "}
              School address:{" "}
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                {promotionalReportData.address}
              </Typography>
            </Typography>
          </TableCell>
        </TableRow>
      );
    },
  };


  const dropDownHandleChange = (event: SelectChangeEvent) => {
    setDropDownStatus(event.target.value);

  };

  return (
    <>
      <MUIDataTable
        title={title}
        data={promotionalReports?.data as any}
        columns={columns(
          promotionalReports?.data,
          handleDelete,
          handleIsLocked,
          roles
        )}
        options={options}
        components={{
          TableFilterList: (props) => {
            return <TableFilterList {...props} />;
          },
          TableToolbar: (props) => {
            return (
                <>
                  <TableToolbar {...props} />

                </>
            );
          },
          icons: {
            FilterIcon: FilterAltRounded as unknown as ReactNode,
            ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
          },
        }}
      />


      <StudentInfoDialog
        open={isOpenStudentInfo}
        handleClose={handleCloseStudentInfo}
        student={student}
      />
    </>
  );
};

export default PromotionalReportTable;
