import {
    Avatar,
    Box,
    Breadcrumbs,
    Card,
    CardHeader,
    CircularProgress,
    Container,
    FormControl,
    IconButton,
    Link,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import {StyledTableRow} from "../components/Table/StylesTableRow";
import {StyledTableCell} from "../components/Table/StylesTableCell";
import {useGetStudentQuery} from "../hooks/useGetStudentQuery";
import React, {useEffect, useMemo, useState} from "react";
import {Helmet} from "react-helmet-async";
import _ from "lodash";
import {CheckCircleOutline, CheckCircleRounded, Grade, GrainRounded, SchoolRounded} from "@mui/icons-material";
import {useStudent} from "../hooks/useStudent";
import {alpha} from "@mui/material/styles";
import {useStudentEvaluationQuery} from "../hooks/useStudentEvaluationQuery";
import {a11yProps, setErrorSnackbar} from "../helper/utils/helper";
import TabPanel from "../components/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import {useTranscriptOfRecordsQuery} from "../hooks/useTranscriptOfRecordsQuery";
import notransaction from "../assets/no-transaction-history.json";
import Lottie from "lottie-react";
import {RootState, useAppSelector} from "../redux/store";
import {setTabValue} from "../redux/features/student";
import {useDispatch, useSelector} from "react-redux";
import StudentFileManager from "../components/FileUpload/StudentFileManager";
import {useAddEntities} from "../hooks/useAddEntities";
import {setFiles} from "../redux/features/specialOrder";
import useDialog from "../hooks/useDialog";
import AddConfirmationDialog from "../components/dialogs/AddConfirmation.dialog";
import {studentFileManagerSave} from "../components/FileUpload/SaveFileManager";
import {ISnackbarSlice, openSnackbar} from "../redux/features/snackbar";
import {useQueryClient} from "react-query";
import {setLoading} from "../redux/features/app";
import {useRequirementQuery} from "../hooks/useRequirementQuery";
import {useRequirementSoQuery} from "../hooks/useRequirementSoQuery";
import {useStudentRequirementSoQuery} from "../hooks/useStudentRequirementSoQuery";
import {useEffectStudentRequirementSo} from "../hooks/useEffectStudentRequirementSo";
import GraduateIcon from "../svg/graduate";
import {FileArchive, Users2} from "lucide-react";

interface IStudentPageProps {
    student_Id?: any;
    header?: boolean;
    tbValue?: number;
    disableRequirement?: boolean
    disableEvaluation?: boolean
}
type Curriculum = {
    name: string;
    program: {
        id: number;
        major: { name: string | null; code?: string | null; id?: number | null } | null;
        name: string;
    };
};

type Grade = {
    id: number;
    curriculumId: number;
    curriculum?: Curriculum | null;
};

type Record = {
    semCode: string;
    semDescription: string | null;
    grades: Grade[];
};

type DataItem = {
    yearLevel: string;
    programName: string;
    majorName: string | null;
    majorId: number | null;
    curriculum: null;
    records: Record[];
    curriculumName?: string | null;
};

export function filterRecordsByProgramAndMajor(data: DataItem[]): DataItem[] {
    return data?.map((item:any) => {
        const { programName, majorName } = item;
        const filteredRecords = item.records.filter((record:any) =>
            record.grades.some((grade:any) =>
                grade.curriculum &&
                grade.curriculum?.program &&
                grade.curriculum?.program?.name === programName &&
                (!majorName ||
                    (grade.curriculum?.program?.major &&
                        grade.curriculum?.program?.major?.name === majorName))
            )
        );

        const curriculumName =
            filteredRecords.length > 0 &&
            filteredRecords[0].grades.length > 0 &&
            filteredRecords[0].grades[0].curriculum
                ? filteredRecords[0].grades[0].curriculum.name
                : null;

        return {
            ...item,
            curriculumName,
            records: filteredRecords
        };
    });
}

const StudentPage = ({
                         student_Id = 0,
                         header = true,
                         tbValue = 0,
                         disableRequirement = false,
                         disableEvaluation = false
                     }: IStudentPageProps) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const files = useSelector((store: RootState) => store.specialOrder?.requirements?.files)
    const user = useSelector((store: RootState) => store.auth.user);
    const specialOrderStudentNumberValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderStudentNumberValue
    );
    const specialOrderStudentIdValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderStudentIdValue
    );
    const specialOrderSchoolId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderSchoolId
    );
    const specialOrderMajorIdValue = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorIdValue)
    const specialOrderProgramId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderProgramId
    );
    const queryKey = useAppSelector(
        (store) => store?.specialOrder?.queryKey
    );
    const {studentId} = useParams() as { studentId: string };
    const {schoolId, regionId, userInstitutionalName} = useAddEntities();


    const [value, setValue] = useState(0);
    const tabValue = useAppSelector((store) => store?.student.tabValue);

    const [confirmationNewValue, setConfirmationNewValue] = useState<number | string | undefined>()
    const [selectedCurriculum, setSelectedCurriculum] = useState("");
    const [selectedProgram, setSelectedProgram] = useState("");
    const [semesterValue, setSemesterValue] = useState(0);
    const [statusValue, setStatusValue] = useState("ALL");
    const [courseCodeValue, setCourseCodeValue] = useState<number>(0);
    const [semesterTORValue, setSemesterTORValue] = useState(0);
    const [semesterDropDownValue, setSemesterDropDownValue] = useState(0);
    const [yearLevelValue, setYearLevelValue] = useState(0);
    const {data: student, isFetching: studentFetching} = useGetStudentQuery(studentId ?? student_Id, +(schoolId ?? schoolId ?? specialOrderSchoolId));
    const {data: studentEvaluation, isFetching: studentEvaluationIsFetching} = useStudentEvaluationQuery({
        studentId: +(studentId ?? student_Id),
        ...schoolId && {schoolId: +(schoolId ?? schoolId ?? specialOrderSchoolId),},
        ...regionId && {regionId: regionId}
    });
    const {
        data: studentTranscriptOfRecords,
        isFetching: studentTranscriptOfRecordsLoading
    } = useTranscriptOfRecordsQuery({
        studentId: +(studentId ?? student_Id),
        schoolId: +(schoolId ?? schoolId ?? specialOrderSchoolId),
        ...regionId && {regionId: regionId}
    });
    const studentMemo = useStudent(studentTranscriptOfRecords, value, semesterValue, student);

    const courseGroupByProgram = _(studentEvaluation?.data?.length > 0 ? studentEvaluation?.data : filterRecordsByProgramAndMajor(student?.data?.enrollments)  )
        .flatten()
        .groupBy("programName")
        .value();



    const coursesGroupByCurriculum = _.groupBy(
        courseGroupByProgram?.[selectedProgram],
        "curriculumName"
    );
    const curriculums = Object.keys(coursesGroupByCurriculum)?.length > 0 ? Object.keys(coursesGroupByCurriculum) : studentMemo?.propertyNames;;
    const programs = Object.keys(courseGroupByProgram)?.length > 0 ? Object.keys(courseGroupByProgram) : studentMemo?.coursePropertyNames;
    const history = useHistory<any>();
    let columns = [
        {id: "yearLevel", label: "YEAR LEVEL", minWidth: 170, align: "center"},
        {id: "semCode", label: "SEMESTER", minWidth: 170, align: "left"},
        {id: "code", label: "COURSE CODE", minWidth: 70, align: "left"},
        {
            id: "description",
            label: "DESCRIPTION",
            minWidth: 200,
            align: "left",
        },
        {id: "prerequisiteCourseCodes", label: "PREREQUISITE COURSE CODES", minWidth: 70, align: "center"},
        {id: "noOfUnits", label: "NO. OF UNITS", minWidth: 70, align: "center"},
        {id: "grades", label: "GRADES", minWidth: 70, align: "center"},
        {id: "remarks", label: "REMARKS", minWidth: 170, align: "center"},
    ];

    const mappedColor = {
        PASSED: "#62C36F",
        FAILED: "#EE1519",
        INCOMPLETE: "#F5672B",
        DROPPED: "#EE1519",
        undefined: "#ffffff",
    };

    const mappedYearLevel = {
        1: "1st Year",
        2: "2nd Year",
        3: "3rd Year",
        4: "4th Year",
        5: "5th Year",
        null: "-",
    };

    const mappedSemester = {
        1: "1st Semester",
        2: "2nd Semester",
        3: "MidYear (Summer)",
        null: "-",
    };
    const StudentInfoTable = (props: any) => {

        return (
            <div>
                <TableContainer component={Paper} sx={{borderRadius: 2}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                {columns.map((column) => (
                                    <StyledTableCell
                                        key={column.id}
                                        sx={{
                                            minWidth: column.minWidth,
                                            textAlign: column.align,
                                            px: 1,
                                        }}
                                    >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {(coursesGroupByCurriculum?.[selectedCurriculum] ?? []).map(
                                (course, i) => {
                                    const subjectsGroupByYearLevel = _.groupBy(
                                        course?.subjects,
                                        "courseYearLevel"
                                    );

                                    return Object.keys(subjectsGroupByYearLevel)?.map((key) => {
                                        const subjectGroupBySemester = _.groupBy(
                                            subjectsGroupByYearLevel[key],
                                            "courseSemester"
                                        );
                                        return (
                                            <React.Fragment key={key}>
                                                {Object.keys(subjectGroupBySemester).map((key) => {

                                                    return (
                                                        <React.Fragment>
                                                            {(subjectGroupBySemester?.[key] ?? []).map(
                                                                (subject, i) => {
                                                                    if (["PASSED", "FAILED", "INCOMPLETE", "DROPPED", "undefined"].indexOf(subject?.remarks as any) > -1) {

                                                                    }

                                                                    return (
                                                                        <StyledTableRow
                                                                            key={i}
                                                                            hover
                                                                            role="checkbox"
                                                                            sx={{
                                                                                bgcolor: alpha(
                                                                                    mappedColor?.[
                                                                                        ["PASSED", "FAILED", "INCOMPLETE", "DROPPED", "undefined"].indexOf(subject?.remarks as any) > -1 ?
                                                                                            ((subject?.remarks ??
                                                                                                "undefined") as keyof typeof mappedColor) : "undefined"
                                                                                        ],
                                                                                    0.25
                                                                                ),
                                                                            }}
                                                                        >
                                                                            {columns.map((column) => {
                                                                                const mappedValue = {
                                                                                    yearLevel:
                                                                                        mappedYearLevel[
                                                                                            +subject.courseYearLevel as keyof typeof mappedYearLevel
                                                                                            ],
                                                                                    semCode:
                                                                                        mappedSemester[
                                                                                            +subject.courseSemester as keyof typeof mappedSemester
                                                                                            ],
                                                                                    code: subject.courseCode,
                                                                                    name: subject?.courseName,
                                                                                    description:
                                                                                    subject?.courseDescription,
                                                                                    prerequisiteCourseCodes:
                                                                                        subject?.coursePrerequisiteCourseCodes ?? "-",
                                                                                    noOfUnits: subject.courseUnit ?? (subject?.unit ? (+subject?.unit?.reduce((sum: number, lecture: any) => (sum ?? 0) + ((lecture?.lecture ?? 0) + (lecture?.laboratory ?? 0)), 0) ?? 0) : 0),
                                                                                    grades: subject.grades ?? "-",
                                                                                    remarks: subject.remarks ?? "-",
                                                                                };
                                                                                const value =
                                                                                    mappedValue[
                                                                                        column.id as keyof typeof mappedValue
                                                                                        ];
                                                                                return (
                                                                                    <StyledTableCell
                                                                                        key={column.id}
                                                                                        sx={{
                                                                                            p: 1,
                                                                                            textAlign: column.align,
                                                                                            // color: "whitesmoke",
                                                                                        }}
                                                                                    >
                                                                                        {value}
                                                                                    </StyledTableCell>
                                                                                );
                                                                            })}
                                                                        </StyledTableRow>
                                                                    );
                                                                }
                                                            )}
                                                            <StyledTableRow hover role="checkbox">
                                                                <StyledTableCell
                                                                    colSpan={5}
                                                                    sx={{
                                                                        p: 1,
                                                                        textAlign: "right",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    TOTAL UNITS:
                                                                </StyledTableCell>

                                                                <StyledTableCell
                                                                    colSpan={1}
                                                                    sx={{
                                                                        p: 1,
                                                                        textAlign: "center",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    {(subjectGroupBySemester[key] ?? []).reduce(
                                                                        (acc, curr) => {
                                                                            return +(curr.courseUnit ?? (curr?.unit ? (+curr?.unit?.reduce((sum: number, lecture: any) => (sum ?? 0) + ((lecture?.lecture ?? 0) + (lecture?.laboratory ?? 0)), 0) ?? 0) : 0)) + acc;
                                                                        },
                                                                        0
                                                                    )}
                                                                </StyledTableCell>
                                                                <StyledTableCell colSpan={2}></StyledTableCell>
                                                            </StyledTableRow>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    });
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };

    const StudentInfo = (props: any) => {
        return <StudentInfoTable records={props.enrollments}></StudentInfoTable>;
    };

    const handleTorChange = (event: SelectChangeEvent) => {
        setSemesterTORValue(0)
        setValue(+event.target.value);
    };


    const statusMemo = useMemo(() => {
        return [
            "ALL",
            "PASSED",
            "FAILED",
            "INCOMPLETE",
            "DROPPED"
        ]
    }, [])


    const programGradesMemo = useMemo(() => {
        return studentMemo?.groupByProgramCourse?.filter((c:any) => c?.courseId != null)?.map((pg: any) => {
            return {
                id: pg.courseId,
                name: pg.courseCode
            }
        })
    }, [studentMemo?.groupByProgramCourse])



    const {
        handleOpen: handleOpenFilesConfirmation,
        handleClose: handleCloseFilesConfirmation,
        open: openFilesConfirmation,
    } = useDialog();

    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const handleSaveFilesConfirmation = async (newValue: number | string | undefined) => {
        try {
            handleCloseFilesConfirmation()

            dispatch(setLoading(true))
            let _queryKey = `specialOrderGroupByStudents${specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId}`
            var _files: any[] = await studentFileManagerSave(files, user, specialOrderStudentNumberValue, (schoolId ?? coursesGroupByCurriculumSchoolId), (coursesGroupByCurriculumProgramId), coursesGroupByCurriculumMajorId, studentId ?? student_Id, setSnackbarStore, queryClient, specialOrderStudentIdValue, queryKey ?? _queryKey);
            if (newValue != null) {
                dispatch(setTabValue(newValue))
            }
            dispatch(setFiles([..._files.map(c => {
                var _c = {...c}
                if (_c?.files?.length > 0) {
                    _c.files = _c.files.filter((c: any) => c.id)
                }
                return _c
            })]))
            handleCloseFilesConfirmation()
            dispatch(setLoading(false))
        } catch (e) {
            dispatch(setLoading(false))
            setErrorSnackbar(e, setSnackbarStore);
            handleCloseFilesConfirmation()
        }

    }
    const semesterMemo = useMemo(() => {
        return _.uniq(studentMemo?.groupByProgramCourse?.map((pg: any) => {
            return pg.courseSemester
        }))
    }, [studentMemo?.groupByProgramCourse])
    const yearLevelMemo = useMemo(() => {
        return _.uniq(studentMemo?.groupByProgramCourse?.map((pg: any) => {
            return pg.courseYearLevel
        }))
    }, [studentMemo?.groupByProgramCourse])
    useEffect(() => {

        setSelectedProgram("")
        setSelectedCurriculum("")
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        dispatch(setFiles([]))

        dispatch(setTabValue(tbValue ?? 0))
    }, [studentId ?? student_Id])

    const _coursesGroupByCurriculum = useMemo(() => {
        return coursesGroupByCurriculum?.[selectedCurriculum]?.find(c => c?.["curriculumName"] == selectedCurriculum)
    }, [coursesGroupByCurriculum?.[selectedCurriculum]?.find(c => c?.["curriculumName"] == selectedCurriculum)])


    const schoolName = useMemo(() => {
        return _coursesGroupByCurriculum?.school
    }, [_coursesGroupByCurriculum?.school])


    const schoolEnrollmentDate = useMemo(() => {
        return _coursesGroupByCurriculum?.enrollmentDate
    }, [_coursesGroupByCurriculum?.enrollmentDate])

    const coursesGroupByCurriculumIsGraduated = useMemo(() => {
        return _coursesGroupByCurriculum?.isGraduated
    }, [_coursesGroupByCurriculum?.isGraduated])
    const coursesGroupByCurriculumMajorId = useMemo(() => {
        return _coursesGroupByCurriculum?.majorId
    }, [_coursesGroupByCurriculum?.majorId])

    const coursesGroupByCurriculumProgramId = useMemo(() => {
        return _coursesGroupByCurriculum?.programId
    }, [_coursesGroupByCurriculum?.programId])

    const coursesGroupByCurriculumSchoolId = useMemo(() => {
        return _coursesGroupByCurriculum?.schoolId
    }, [_coursesGroupByCurriculum?.schoolId])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {

        for (let i = 0; i < files?.length; i++) {

            if (files?.[i]?.files?.filter((c: any) => !c.schoolId && !c.programId)?.length > 0) {
                handleOpenFilesConfirmation()
                setConfirmationNewValue(newValue)
                return
            }
        }
        dispatch(setTabValue(newValue));
    };
    const handleSemesterChange = (event: any) => {
        setSemesterTORValue(+event.target.value);
    };


    const handleSemester = (event: any) => {
        setSemesterDropDownValue(+event.target.value);
    };

    const handleStatus = (event: any) => {
        setStatusValue(event.target.value);
    };

    const handleCourseCode = (event: any) => {
        setCourseCodeValue(event.target.value);
    };


    const handleYearLevelCode = (event: any) => {
        setYearLevelValue(event.target.value);
    };

    const TranscriptOfRecordsStudentInfoTable = (props: any) => {
        let columns = [
            {id: 'courseCode', label: 'COURSE CODE', minWidth: 170, align: 'left'},
            {id: 'description', label: 'DESCRIPTION', minWidth: 170, align: 'left'},
            {id: 'noOfUnits', label: 'NO. OF UNITS', minWidth: 170, align: 'left'},
            {id: 'grades', label: 'GRADES', minWidth: 170, align: 'left'},
            {id: 'remarks', label: 'REMARKS', minWidth: 170, align: 'left'},
        ];

        var grades = props.grades.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => ["unit", "yearLevel", 'courseCode', 'courseDescription', 'courseId', 'courseName', 'courseUnit', 'courseSemester', 'courseYearLevel', "noOfUnits", "grades", "remarks"].every(k => v2[k] === v[k])) === i)

        return (
            <div>
                {grades ?
                    <TableContainer component={Paper} sx={{borderRadius: 2}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align={"center"}>COURSE CODE</StyledTableCell>
                                    <StyledTableCell align="center">YEAR LEVEL</StyledTableCell>
                                    <StyledTableCell align="center">SEMESTER</StyledTableCell>
                                    <StyledTableCell align="center">DESCRIPTION</StyledTableCell>
                                    <StyledTableCell align="center">NO. OF UNITS</StyledTableCell>
                                    <StyledTableCell align="center">GRADES</StyledTableCell>
                                    <StyledTableCell align="center">REMARKS</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {grades?.sort(function (left: any, right: any) {
                                    var courseCode = left?.courseCode?.localeCompare(right?.courseCode);
                                    var yearLevel = parseInt(right.courseYearLevel) - parseInt(left.courseYearLevel);
                                    return -yearLevel || courseCode;
                                })
                                    .filter((c: any) => {
                                        return (semesterDropDownValue > 0 ? c.courseSemester == semesterDropDownValue : c)
                                    })
                                    .filter((c: any) => {
                                        return (statusValue == "ALL" ? c : c.remarks == statusValue)
                                    })
                                    .filter((c: any) => {
                                        return courseCodeValue > 0 ? c.courseId == courseCodeValue : c
                                    })
                                    .filter((c: any) => {
                                        return yearLevelValue > 0 ? c.courseYearLevel == yearLevelValue : c
                                    })
                                    .filter((c: any) => {
                                        return c.courseId > 0
                                    })
                                    .map((grade: any, index: number) => {

                                        return (
                                            <TableRow
                                                key={grade?.courseCode}
                                                sx={{
                                                    bgcolor: alpha(
                                                        mappedColor?.[
                                                            ["PASSED", "FAILED", "INCOMPLETE", "DROPPED", "undefined"].indexOf(grade?.remarks as any) > -1 ?
                                                                ((grade?.remarks ??
                                                                    "undefined") as keyof typeof mappedColor) : "undefined"
                                                            ],
                                                        0.25
                                                    ),
                                                    '&:last-child td, &:last-child th': {border: 0}
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <p>{grade?.courseCode}</p>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {mappedYearLevel[+grade?.courseYearLevel as keyof typeof mappedYearLevel]}
                                                </TableCell>
                                                <TableCell
                                                    align="center">{mappedSemester[+grade?.courseSemester as keyof typeof mappedSemester]}</TableCell>
                                                <TableCell align="center">{grade?.courseDescription}</TableCell>
                                                <TableCell
                                                    align="center">{+grade?.unit?.reduce((sum: number, lecture: any) => (sum ?? 0) + ((lecture?.lecture ?? 0) + (lecture?.laboratory ?? 0)), 0)}</TableCell>
                                                <TableCell align="center">{grade?.grades} </TableCell>
                                                <TableCell align="center">{grade?.remarks}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : null
                }

            </div>
        )
    };

    const TranscriptOfRecordsStudentInfo = (props: any) => {

        return (
            props.program?.length > 0 ?
                <TranscriptOfRecordsStudentInfoTable grades={props.program}></TranscriptOfRecordsStudentInfoTable> :
                <div style={{
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {
                        studentTranscriptOfRecordsLoading || studentFetching || studentEvaluationIsFetching ? <CircularProgress/> :
                            <Typography variant="subtitle1" gutterBottom>
                                No Record Yet!
                            </Typography>
                    }
                </div>
        )
    };
    const {
        data: requirements,
        isLoading,
        refetch: refetch
    } = useRequirementQuery(coursesGroupByCurriculumProgramId, coursesGroupByCurriculumSchoolId, +(studentId ?? student_Id));
    const {
        data: requirementSO,
        isLoading: isLoadingSo,
        refetch: refetchSo
    } = useRequirementSoQuery(coursesGroupByCurriculumProgramId, coursesGroupByCurriculumSchoolId, +(studentId ?? student_Id));
    const {
        data: studentrequirementSO,
        isLoading: isLoadingStudentRequirementSO,
        refetch: refetchStudentRequirementSO
    } = useStudentRequirementSoQuery(coursesGroupByCurriculumProgramId, +(schoolId ?? schoolId ?? coursesGroupByCurriculumSchoolId ?? specialOrderSchoolId), +(studentId ?? student_Id), specialOrderStudentNumberValue, coursesGroupByCurriculumMajorId);


    const CheckMemo = useMemo(() => {


        var _requirementQuery: any = requirements?.data?.filter((c: any) => c.isRequired)
        if (!_requirementQuery?.length) return []

        var ____files = _requirementQuery.map((c: any) => {
            return {
                id: c.id,
                name: c.name,
                isEnabled: true
            }
        })


        var ___files = [...____files?.map((c: any) => {

            var __requirementQueryIndex = files?.findIndex((r: any) => {
                return r?.id == c?.id
            })
            if (__requirementQueryIndex > -1) {
                return {
                    name: c.name,
                    isEnabled: true
                }
            }
            return {
                name: c.name,
                isEnabled: c?.files?.length > 0
            }
        })]

        if (requirements?.data?.length) {
            requirementSO?.data?.forEach((c: any) => {
                queryClient.setQueriesData('requirementQuery', (oldData: any) => {
                    return {
                        ...oldData,
                        data: oldData?.data?.map((sor: any) => {
                            if (c.specialOrderRequirementId == sor.id) {
                                sor.isRequired = true
                            }
                            return sor
                        })
                    }
                })
            })
        }
        return ___files
    }, [files, requirements, student_Id, studentId])


    useEffectStudentRequirementSo(files, studentrequirementSO, specialOrderStudentNumberValue, dispatch);

    return (
        <>
            <Helmet>
                <title>Student Details</title>
            </Helmet>
            <Box
                component="main"
                sx={header ? {flexGrow: 1, pt: 10} : {}}
                style={{
                    paddingLeft: " 20px",
                    paddingRight: "20px",
                }}
            >
                <Container>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                    >
                        <Box>
                            <Typography variant="h5" fontWeight="bold">
                                Student Details
                            </Typography>
                        </Box>
                    </Box>
                    <Paper>
                        <Breadcrumbs sx={{p: 2}}>
                            <Link
                                underline="hover"
                                sx={{display: "flex", alignItems: "center"}}
                                color="inherit"
                                href="/students"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push(history.location.state.from);
                                }}
                            >
                                <SchoolRounded sx={{mr: 0.5}} fontSize="inherit"/>
                                Student
                            </Link>

                            <Typography
                                sx={{display: "flex", alignItems: "center"}}
                                color="text.primary"
                            >
                                <GrainRounded sx={{mr: 0.5}} fontSize="inherit"/>
                                {history.location.state?.title}
                            </Typography>
                        </Breadcrumbs>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                            }}
                        >
                            {student?.data?.enrollments?.length ? (
                                <>
                                    {/*<Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                        >
                            {
                                studentMemo.propertyNames.map((c: any, index: number) => {
                                    return <Tab label={c} {...a11yProps(index)} />
                                })
                            }
                        </Tabs>*/}
                                    <div
                                        style={{
                                            paddingBottom: 30,
                                            paddingRight: 30,
                                            paddingLeft: 30,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                paddingBottom: 2,
                                            }}
                                        >
                                            <Card>
                                                <Stack direction="row" alignItems="center" gap={15}>
                                                    <CardHeader
                                                        avatar={<Avatar/>}
                                                        titleTypographyProps={{variant: "subtitle1"}}
                                                        subheaderTypographyProps={{variant: "subtitle2"}}
                                                        title={`${student.data.firstName ?? ""} ${
                                                            student.data.middleName ?? ""
                                                        } ${student.data.lastName} ${
                                                            student.data.extName ?? ""
                                                        }`}
                                                        subheader={
                                                            <div>
                                                                Student Number: {student.data.studentNumber}
                                                                {student?.data?.school ?<Typography>
                                                                    School Name: <strong>{student?.data?.school}</strong>
                                                                </Typography> : null}

                                                            </div>
                                                        }

                                                    />
                                                    <Box>


                                                        <Typography>
                                                            Gender: <strong>{student.data.sex}</strong>
                                                        </Typography>
                                                        {
                                                            schoolEnrollmentDate ?
                                                                <Typography>
                                                                    Enrollment
                                                                    Date: <strong>{schoolEnrollmentDate}</strong>
                                                                </Typography>
                                                                : null
                                                        }

                                                        <Typography>
                                                            Year Level: <strong>{student?.data?.yearLevel}</strong>
                                                        </Typography>

                                                    </Box>
                                                    <Box>
                                                        <Typography>
                                                            Nationality:{" "}
                                                            <strong>{student.data.nationality}</strong>
                                                        </Typography>
                                                        <Typography>
                                                            Academic Status:{" "}
                                                            <strong>
                                                                <label
                                                                    style={{color: coursesGroupByCurriculumIsGraduated == undefined ? (student?.data?.isGraduated ? "#2e7d32" : "#d32f2f") : (coursesGroupByCurriculumIsGraduated ? "#2e7d32" : "#d32f2f")}}>
                                                                    {coursesGroupByCurriculumIsGraduated == undefined ? (student?.data?.isGraduated ? "Completed" : "Incomplete") : (coursesGroupByCurriculumIsGraduated ? "Completed" : "Incomplete")}
                                                                </label>
                                                            </strong>
                                                        </Typography>
                                                        {coursesGroupByCurriculumProgramId && coursesGroupByCurriculumSchoolId ?
                                                            <>
                                                                <Typography>
                                                                    Requirement Status:{" "}

                                                                </Typography>
                                                                {CheckMemo ? CheckMemo.map((c: any) => {
                                                                    return c.isEnabled ?
                                                                        <Tooltip title={c?.name ?? ""} arrow>
                                                                            <IconButton name={c.name}>
                                                                                <CheckCircleRounded color={"success"}/>
                                                                            </IconButton>
                                                                        </Tooltip> :
                                                                        <Tooltip title={c?.name ?? ""} arrow>
                                                                            <IconButton name={c.name}>
                                                                                <CheckCircleOutline color={"error"}/>
                                                                            </IconButton></Tooltip>
                                                                }) : null}
                                                            </> : null
                                                        }

                                                    </Box>
                                                </Stack>
                                            </Card>
                                        </Box>

                                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                            <Tabs value={tabValue} onChange={handleChange} aria-label="Evaluation">
                                                <Tab iconPosition={"start"} icon={<Users2/>} disabled={disableEvaluation}
                                                     label="Student Evaluation" {...a11yProps(0)} />
                                                <Tab iconPosition={"start"}  icon={<Grade/>} label="Transcript Of Records" {...a11yProps(1)} />
                                                <Tab iconPosition={"start"} icon={<FileArchive/>} disabled={disableRequirement}
                                                     label="Requirements" {...a11yProps(2)} />
                                            </Tabs>
                                        </Box>
                                        <TabPanel value={tabValue} index={0}>
                                            <div style={{paddingTop: 30, paddingBottom: 30}}>
                                                <Stack gap={1} direction="row">
                                                    <TextField
                                                        size={"small"}
                                                        select
                                                        inputProps={{
                                                            'id': `program-select`,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {isLoading ? (
                                                                        <CircularProgress
                                                                            color="inherit"
                                                                            size={20}/>
                                                                    ) : null}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        id={"student-program-id"}
                                                        fullWidth
                                                        sx={{width: 300}}
                                                        label="Programs"
                                                        onChange={(e) => setSelectedProgram(e.target.value)}
                                                        value={selectedProgram}
                                                    >
                                                        {programs.map((program, index) => {
                                                            return (
                                                                <MenuItem key={index} value={program}>
                                                                    {program}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                    <TextField
                                                        size={"small"}
                                                        select
                                                        fullWidth
                                                        id={"curriculum-program"}
                                                        inputProps={{
                                                            'id': `program-select`,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {isLoading ? (
                                                                        <CircularProgress
                                                                            color="inherit"
                                                                            size={20}/>
                                                                    ) : null}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        sx={{width: 300}}
                                                        label="Curriculums"
                                                        onChange={(e) =>
                                                            setSelectedCurriculum(e.target.value)
                                                        }
                                                        value={selectedCurriculum}
                                                    >
                                                        {curriculums.map((c, index) => {
                                                            return (
                                                                <MenuItem key={index} value={c}>
                                                                    {c}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                </Stack>
                                            </div>
                                            <StudentInfo
                                                enrollments={studentMemo?.groupByProgramCourse}
                                            ></StudentInfo>
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={1}>
                                            {student?.data?.enrollments?.length ?
                                                <>
                                                    <div style={{paddingTop: 30, paddingBottom: 30,}}>
                                                        <div style={{paddingBottom: 20}}>
                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,

                                                                flexDirection: "row",
                                                                alignItems: "center"
                                                            }}>
                                                                <div>
                                                                    {studentMemo?.coursePropertyNames?.length > 0 ?
                                                                        <FormControl>
                                                                            <InputLabel
                                                                                id="program-select">Programs</InputLabel>
                                                                            <Select
                                                                                aria-label={"program-select-role-label"}
                                                                                size={"small"}
                                                                                onChange={handleSemesterChange}
                                                                                labelId="program-select"
                                                                                id="program-select"
                                                                                inputProps={{
                                                                                    'id': `program-select`,
                                                                                    endAdornment: (
                                                                                        <React.Fragment>
                                                                                            {isLoading ? (
                                                                                                <CircularProgress
                                                                                                    color="inherit"
                                                                                                    size={20}/>
                                                                                            ) : null}
                                                                                        </React.Fragment>
                                                                                    ),
                                                                                }}
                                                                                SelectDisplayProps={{
                                                                                    // @ts-ignore
                                                                                    "id": `program-select`
                                                                                }}
                                                                                label="Programs"
                                                                                value={semesterTORValue?.toString()}
                                                                            >
                                                                                {
                                                                                    studentMemo.coursePropertyNames.map((c: any, index: number) => {
                                                                                        return <MenuItem
                                                                                            id={"program-" + index}
                                                                                            value={index}>{c}</MenuItem>
                                                                                    })
                                                                                }


                                                                            </Select>
                                                                        </FormControl> : null
                                                                    }

                                                                </div>


                                                                <div style={{paddingLeft: 6}}>
                                                                    {studentMemo?.propertyNames?.length > 0 ?
                                                                        <FormControl>
                                                                            <InputLabel
                                                                                id="curriculum-select">Curriculums</InputLabel>
                                                                            <Select
                                                                                inputProps={{
                                                                                    'id': `curriculum-select`,
                                                                                    endAdornment: (
                                                                                        <React.Fragment>
                                                                                            {isLoading ? (
                                                                                                <CircularProgress
                                                                                                    color="inherit"
                                                                                                    size={20}/>
                                                                                            ) : null}
                                                                                        </React.Fragment>
                                                                                    ),
                                                                                }}
                                                                                SelectDisplayProps={{
                                                                                    // @ts-ignore
                                                                                    "id": `curriculum-select`
                                                                                }}
                                                                                aria-label={"curriculum-select-role-label"}
                                                                                id="curriculum-select"
                                                                                size={"small"}
                                                                                onChange={handleTorChange}
                                                                                labelId="curriculum-select"

                                                                                label="Curriculums"
                                                                                value={value?.toString()}
                                                                            >
                                                                                {
                                                                                    studentMemo.propertyNames.map((c: any, index: number) => {
                                                                                        return <MenuItem
                                                                                            id={"curriculum-" + index}
                                                                                            value={index}>{c}</MenuItem>
                                                                                    })
                                                                                }


                                                                            </Select>
                                                                        </FormControl> : null}
                                                                </div>


                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                paddingTop: 20,
                                                                flexDirection: "row",
                                                                alignItems: "center"
                                                            }}>
                                                                <div style={{}}>
                                                                    {programGradesMemo?.length > 0 ?
                                                                        <div>
                                                                            <FormControl>
                                                                                <InputLabel
                                                                                    id="Course-Code-select-label">Course
                                                                                    Code</InputLabel>
                                                                                <Select
                                                                                    inputProps={{
                                                                                        'id': `Course-Code-select`,
                                                                                        endAdornment: (
                                                                                            <React.Fragment>
                                                                                                {isLoading ? (
                                                                                                    <CircularProgress
                                                                                                        color="inherit"
                                                                                                        size={20}/>
                                                                                                ) : null}

                                                                                            </React.Fragment>
                                                                                        ),
                                                                                    }}
                                                                                    aria-label={"course-select-role-label"}
                                                                                    SelectDisplayProps={{
                                                                                        // @ts-ignore
                                                                                        "id": `Course-Code-select`
                                                                                    }}
                                                                                    size={"small"}
                                                                                    style={{width: 160}}
                                                                                    onChange={handleCourseCode}
                                                                                    labelId="Course-Code-select-label"
                                                                                    id="Course-Code-select"
                                                                                    label="Course Code"
                                                                                    value={courseCodeValue}
                                                                                >
                                                                                    <MenuItem key={0}
                                                                                              value={0}>ALL</MenuItem>
                                                                                    {
                                                                                        _.uniqWith(programGradesMemo?.map((c: any, index: number) => {
                                                                                            return <MenuItem key={index}
                                                                                                             value={c?.id}>{c?.name}</MenuItem>
                                                                                        }), function(arrVal:any, othVal:any) {
                                                                                            return arrVal?.id === othVal?.id;
                                                                                        })
                                                                                    }


                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                        : null
                                                                    }

                                                                </div>
                                                                <div style={{paddingLeft: 6}}>
                                                                    {statusMemo?.length > 0 && studentMemo?.groupByProgramCourse ?
                                                                        <FormControl style={{width: "100%"}}>
                                                                            <InputLabel
                                                                                id="Course-Code-select-label">Status</InputLabel>
                                                                            <Select  aria-label={"coursecoude-select-role-label"}
                                                                                inputProps={{
                                                                                    'id': `Course-Code-select`,
                                                                                    endAdornment: (
                                                                                        <React.Fragment>
                                                                                            {isLoading ? (
                                                                                                <CircularProgress
                                                                                                    color="inherit"
                                                                                                    size={20}/>
                                                                                            ) : null}

                                                                                        </React.Fragment>
                                                                                    ),
                                                                                }}

                                                                                SelectDisplayProps={{
                                                                                    // @ts-ignore
                                                                                    "id": `Course-Code-select`
                                                                                }}
                                                                                size={"small"}
                                                                                style={{width: 160}}
                                                                                onChange={handleStatus}
                                                                                labelId="demo-status-select-label"
                                                                                id="Course-Code-select"
                                                                                label="Status"
                                                                                value={statusValue?.toString()}
                                                                            >
                                                                                {
                                                                                    statusMemo.map((c: any, index: number) => {
                                                                                        return <MenuItem key={c}
                                                                                                         value={c}>{c}</MenuItem>
                                                                                    })
                                                                                }


                                                                            </Select>
                                                                        </FormControl> : null
                                                                    }

                                                                </div>
                                                                <div style={{paddingLeft: 6}}>
                                                                    {semesterMemo?.length > 0  ?
                                                                        <FormControl style={{width: "100%"}}>
                                                                            <InputLabel
                                                                                id="demo-status-select-label">Semester</InputLabel>
                                                                            <Select
                                                                                aria-label={"status-select-role-label"}
                                                                                inputProps={{
                                                                                    'id': `demo-status-select`,
                                                                                    endAdornment: (
                                                                                        <React.Fragment>
                                                                                            {isLoading ? (
                                                                                                <CircularProgress
                                                                                                    color="inherit"
                                                                                                    size={20}/>
                                                                                            ) : null}

                                                                                        </React.Fragment>
                                                                                    ),
                                                                                }}
                                                                                SelectDisplayProps={{
                                                                                    // @ts-ignore
                                                                                    "id": `Course-Code-select`
                                                                                }}
                                                                                size={"small"}
                                                                                style={{width: 160}}
                                                                                onChange={handleSemester}
                                                                                labelId="demo-status-select-label"
                                                                                id="demo-status-select"
                                                                                label="Status"
                                                                                value={semesterDropDownValue?.toString()}
                                                                            >
                                                                                <MenuItem key={0}
                                                                                          value={0}>ALL</MenuItem>
                                                                                {
                                                                                    semesterMemo.map((c: any, index: number) => {
                                                                                        return <MenuItem key={c}
                                                                                                         value={c}>{c == 1 ? "1st Semester" : "2nd Semester"}</MenuItem>
                                                                                    })
                                                                                }


                                                                            </Select>
                                                                        </FormControl> : null
                                                                    }

                                                                </div>
                                                                <div style={{paddingLeft: 6}}>
                                                                    {yearLevelMemo?.length > 0 ?
                                                                        <FormControl style={{width: "100%"}}>
                                                                            <InputLabel
                                                                                id="demo-year-level-select-label">Year
                                                                                Level</InputLabel>
                                                                            <Select
                                                                                aria-label={"year-level-select-role-label"}
                                                                                size={"small"}
                                                                                inputProps={{
                                                                                    'id': `demo-status-select`,
                                                                                    endAdornment: (
                                                                                        <React.Fragment>
                                                                                            {isLoading ? (
                                                                                                <CircularProgress
                                                                                                    color="inherit"
                                                                                                    size={20}/>
                                                                                            ) : null}

                                                                                        </React.Fragment>
                                                                                    ),
                                                                                }}
                                                                                style={{width: 160}}
                                                                                onChange={handleYearLevelCode}
                                                                                labelId="demo-year-level-select-label"
                                                                                id="demo-year-level-select"
                                                                                label="Year Level"
                                                                                value={yearLevelValue?.toString()}
                                                                            >
                                                                                <MenuItem key={0}
                                                                                          value={0}>ALL</MenuItem>
                                                                                {
                                                                                    yearLevelMemo.map((c: any, index: number) => {
                                                                                        console.log(mappedYearLevel)
                                                                                        return <MenuItem key={c}
                                                                                                         value={c}>{mappedYearLevel?.[c?.toString() as keyof typeof mappedYearLevel]}</MenuItem>
                                                                                    })
                                                                                }


                                                                            </Select>
                                                                        </FormControl> : null
                                                                    }

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <TranscriptOfRecordsStudentInfo
                                                            program={studentMemo?.groupByProgramCourse}></TranscriptOfRecordsStudentInfo>
                                                    </div>


                                                </> : <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    alignSelf: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "column"
                                                }}>
                                                    <Lottie style={{height: "350px"}} loop={true}
                                                            animationData={notransaction}/>
                                                    <Typography variant="body1" gutterBottom>
                                                        No Record Yet!
                                                    </Typography>
                                                </div>
                                            }
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={2}>
                                            <div style={{paddingTop: 30, paddingBottom: 30}}>
                                                <Stack gap={1} direction="row">
                                                    <TextField
                                                        size={"small"}
                                                        select
                                                        id={"student-program-id"}
                                                        fullWidth
                                                        sx={{width: 300}}
                                                        label="Programs"
                                                        onChange={(e) => setSelectedProgram(e.target.value)}
                                                        value={selectedProgram}
                                                    >
                                                        {programs.map((program, index) => {
                                                            return (
                                                                <MenuItem key={index} value={program}>
                                                                    {program}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                    <TextField
                                                        size={"small"}
                                                        select
                                                        fullWidth
                                                        id={"curriculum-program"}
                                                        sx={{width: 300}}
                                                        label="Curriculums"
                                                        onChange={(e) =>
                                                            setSelectedCurriculum(e.target.value)
                                                        }
                                                        value={selectedCurriculum}
                                                    >
                                                        {curriculums.map((c, index) => {
                                                            return (
                                                                <MenuItem key={index} value={c}>
                                                                    {c}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                </Stack>
                                            </div>
                                            {
                                                coursesGroupByCurriculumSchoolId && coursesGroupByCurriculumProgramId ?
                                                    <StudentFileManager studentId={studentId ?? student_Id}
                                                                        schoolId={coursesGroupByCurriculumSchoolId ?? schoolId ?? specialOrderSchoolId}
                                                                        programId={coursesGroupByCurriculumProgramId}
                                                                        majorId={coursesGroupByCurriculumMajorId}
                                                                        title={"Requirements"}
                                                                        handleClose={() => {
                                                                        }}
                                                                        onSave={() => {
                                                                        }}/>
                                                    : <div style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        alignSelf: "center",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        flexDirection: "column"
                                                    }}>
                                                        <Lottie style={{height: "350px"}} loop={true}
                                                                animationData={notransaction}/>
                                                        <Typography variant="h6" gutterBottom>
                                                            Please provide your program and curriculum
                                                        </Typography>
                                                    </div>
                                            }


                                        </TabPanel>

                                    </div>
                                </>
                            ) : (
                                <div
                                    style={{
                                        paddingBottom: 30,
                                        paddingRight: 30,
                                        paddingLeft: 30,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            paddingBottom: 2,
                                        }}
                                    >
                                        <Card>
                                            <Stack direction="row" alignItems="center" gap={15}>
                                                <CardHeader
                                                    avatar={<Avatar/>}
                                                    titleTypographyProps={{variant: "subtitle1"}}
                                                    subheaderTypographyProps={{variant: "subtitle2"}}
                                                    title={`${student?.data?.firstName ?? ""} ${
                                                        student?.data?.middleName ?? ""
                                                    } ${student?.data?.lastName ?? ""} ${
                                                        student?.data?.extName ?? ""
                                                    }`}
                                                    subheader={
                                                        <>Student Number: {student?.data?.studentNumber}</>
                                                    }
                                                />
                                                <Box>
                                                    <Typography>
                                                        Gender: <strong>{student?.data?.sex}</strong>
                                                    </Typography>
                                                    <Typography>
                                                        Year Level: <strong>1st Year</strong>
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography>
                                                        Nationality:{" "}
                                                        <strong>{student?.data?.nationality}</strong>
                                                    </Typography>
                                                    <Typography>
                                                        Academic Status:{" "}
                                                        <strong>
                                                            <label
                                                                style={{color: coursesGroupByCurriculumIsGraduated == undefined ? (student?.data?.isGraduated ? "#2e7d32" : "#d32f2f") : (coursesGroupByCurriculumIsGraduated ? "#2e7d32" : "#d32f2f")}}>
                                                                {coursesGroupByCurriculumIsGraduated == undefined ? (student?.data?.isGraduated ? "Completed" : "Incomplete") : (coursesGroupByCurriculumIsGraduated ? "Completed" : "Incomplete")}
                                                            </label>
                                                        </strong>
                                                    </Typography>

                                                    {coursesGroupByCurriculumIsGraduated ? <Typography>
                                                        Requirement Status:{" "}
                                                        <strong>
                                                            <label
                                                                style={{color: coursesGroupByCurriculumIsGraduated == undefined ? (student?.data?.isGraduated ? "#2e7d32" : "#d32f2f") : (coursesGroupByCurriculumIsGraduated ? "#2e7d32" : "#d32f2f")}}>
                                                                {coursesGroupByCurriculumIsGraduated == undefined ? (student?.data?.isGraduated ? "Completed" : "Incomplete") : (coursesGroupByCurriculumIsGraduated ? "Completed" : "Incomplete")}
                                                            </label>
                                                        </strong>
                                                    </Typography> : null}
                                                </Box>
                                            </Stack>
                                        </Card>
                                    </Box>
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            alignSelf: "center",
                                            alignItems: "center",
                                            justifyContent: "center", flexDirection: "column"
                                        }}
                                    >
                                        <Lottie style={{height: "350px"}} loop={true} animationData={notransaction}/>
                                        {
                                            studentTranscriptOfRecordsLoading || studentFetching || studentEvaluationIsFetching ? <CircularProgress/> :
                                                <Typography variant="subtitle1" gutterBottom>
                                                    No Record Yet!
                                                </Typography>
                                        }

                                    </div>
                                </div>
                            )}
                        </Box>

                    </Paper>
                </Container>
                <AddConfirmationDialog
                    isLoading={isLoading}
                    title={"Do you want to save the changes you made?"}
                    open={openFilesConfirmation}
                    handleClose={() => {
                        handleCloseFilesConfirmation()

                    }}
                    onSave={() => handleSaveFilesConfirmation(confirmationNewValue)}></AddConfirmationDialog>

            </Box>
        </>
    );
};

export default StudentPage;
