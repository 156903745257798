import {
    Block,
    DeleteRounded,
    EventNoteRounded,
    MoreHoriz,
    SchoolRounded,
    ThumbUpRounded,
    VisibilityRounded
} from "@mui/icons-material"
import {
    Avatar,
    Box,
    Chip,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Tooltip,
    Typography
} from "@mui/material"
import React, {MouseEvent, useCallback, useMemo, useState} from "react"
import {useQueryClient} from "react-query"
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router-dom"
import {setLoading} from "../../redux/features/app"
import {ISnackbarSlice, openSnackbar,} from "../../redux/features/snackbar"
import {deleteSchool, updateSchoolStatus} from "../../requests/school.request"
import {SchoolType} from "../../types"
import useMenuDialog from "../dialogs/Menu.dialog"
import useSchoolDetailDialog from "../dialogs/SchoolDetail.dialog"
import {setErrorSnackbar} from "../../helper/utils/helper";
import {setPostCount, setSelectedAcademicCalendar, setSelectedSchool} from "../../redux/features/school";
import {RootState} from "../../redux/store";

const status = {
    active: < Chip  label="Active" variant="outlined" color="success"/>,
    inactive: <Chip label="Inactive" variant="outlined" color="error"/>,
    pending: <Chip label="Pending" variant="outlined" color="warning"/>,
    rejected: <Chip label="Rejected" variant="outlined" color="error"/>,
    approved: <Chip label="Approved" variant="outlined" color="success"/>
}

const SchoolList = ({schools}: { schools: SchoolType[] }) => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const history = useHistory()
    const [selectedSchoolId, setSelectedSchoolId] = React.useState<number | null>(null)
    const authStore = useSelector((store: RootState) => store.auth)

    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const {
        Component: SchoolDetail,
        handleClickOpen,
        handleClose
    } = useSchoolDetailDialog({onClose: () => setSelectedSchoolId(null)})
    const {
        Component: Menu,
        handleClick: handleClickOpenMenu,
        handleClose: handleCloseMenu
    } = useMenuDialog({onClose: () => setSelectedSchoolId(null)})

    const handleDelete = async () => {
        if (selectedSchoolId) {
            try {
                dispatch(setLoading(true))
                await deleteSchool(selectedSchoolId)
                setSnackbarStore({
                    message: 'School deleted successfully', severity: 'success', open: true
                })
                queryClient.setQueriesData('schools', (oldData: any) => {
                    return {
                        ...oldData, data: oldData.data?.filter((school: any) => school.id !== selectedSchoolId)
                    }
                })
            } catch (error: any) {
                console.error({error})
                setErrorSnackbar(error, setSnackbarStore)
            } finally {
                dispatch(setLoading(false))
            }
        }
    }
    const handleApprove = useCallback(async () => {
        if (selectedSchoolId) {
            try {
                dispatch(setLoading(true))
                const res = await updateSchoolStatus(selectedSchoolId, 'approved')
                setSnackbarStore({
                    message: 'School approved successfully. Your request will be reflected after 5 minutes.', severity: 'success', open: true
                })

                queryClient.setQueriesData('schools', (oldData: any) => {
                    return {
                        ...oldData, data: oldData?.data?.map((school: any) => {
                            if (school.id === selectedSchoolId) {
                                res.status = "approved"
                                return res
                            }
                            return school
                        })
                    }
                })
                dispatch(setPostCount())
            } catch (error: any) {
                dispatch(setPostCount())
                setErrorSnackbar(error, setSnackbarStore)
            } finally {
                dispatch(setPostCount())
                dispatch(setLoading(false))
            }
        }
    }, [selectedSchoolId])

    const handleViewStudent = useCallback(async (school: any) => {
        dispatch(setSelectedSchool(school.school))
        history.push(`/students/school/${school.school?.id}`, {from: '/schools'})
    }, [])
    const handleViewAcademic = useCallback(async (school: any) => {
        dispatch(setSelectedAcademicCalendar(school.school))
        history.push(`/academic/calendar/${school.school?.id}`, {from: '/schools'})
    }, [])

    const handleDeactivate = useCallback(async () => {
        if (selectedSchoolId) {
            try {
                dispatch(setLoading(true))
                const res = await updateSchoolStatus(selectedSchoolId, 'inactive')
                setSnackbarStore({
                    message: 'School deactivated successfully. Your request will be reflected after 5 minutes.', severity: 'success', open: true
                })
                queryClient.setQueriesData('schools', (oldData: any) => {
                    return {
                        ...oldData, data: oldData?.data?.map((school: any) => {
                            if (school.id === selectedSchoolId) {
                                res.status = "inactive"
                                return res
                            }
                            return school
                        })
                    }
                })
            } catch (error: any) {
                console.error({error})
                setErrorSnackbar(error, setSnackbarStore)
            } finally {
                dispatch(setLoading(false))
            }

        }
    }, [selectedSchoolId])
    const actions = useMemo(() => {
        return ({
                pending: <MenuItem
                    value="approve"
                    sx={{color: 'success.main',}}
                    onClick={handleApprove}
                >
                    <ListItemIcon sx={{color: 'inherit'}}>
                        <ThumbUpRounded fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Approve</ListItemText>
                </MenuItem>, inactive: <MenuItem
                    value="approve"
                    sx={{color: 'success.main',}}
                    onClick={handleApprove}
                >
                    <ListItemIcon sx={{color: 'inherit'}}>
                        <ThumbUpRounded fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Approve</ListItemText>
                </MenuItem>, approved: <MenuItem
                    onClick={handleDeactivate}
                    sx={{color: 'warning.main',}}
                    value="deactivate"
                >
                    <ListItemIcon sx={{color: 'inherit'}}>
                        <Block fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Deactivate</ListItemText>
                </MenuItem>
            }

        )
    }, [handleApprove, handleDeactivate])
    const selectedSchool = useMemo(() => {
        return schools?.find((school) => school.id === selectedSchoolId)
    }, [selectedSchoolId])
    const onRowClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setSelectedSchoolId(+e.currentTarget.value)
        handleClickOpenMenu(e)
    }, [])
    const onPreviewClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setSelectedSchoolId(+e.currentTarget.value)
        handleClickOpen()
    }, [])
    const handleViewSchool = useCallback(({
                                              school,
                                              schoolId,
                                              title,
                                              region,
                                              institutionalCode
                                          }: { school: any, schoolId: number, title: string, region: string, institutionalCode: string }) => {
        dispatch(setSelectedSchool(school))
        history.push(`/schools/${schoolId}`, {schoolId, region, institutionalCode, from: '/schools', title})
    }, [])

    const levels = useMemo(() => {
        return ["Doctoral", "Baccalaureate", "Basic", "Pre-Baccalaureate", "Post-Baccalaureate", "Masters",

        ]
    }, []);


    const userAdminRoles = useMemo(() => ["user", "admin"].indexOf(authStore?.user?.roles.toLowerCase()), [authStore?.user?.roles])
    const [open, setOpen] = useState(false);






    return <>
        <SchoolDetail school={selectedSchool}/>


        <Menu>

            <MenuList sx={{width: 320, maxWidth: '100%'}}>
                {userAdminRoles == -1 || (authStore?.user?.type?.id === 2 || authStore?.user?.type?.id === 3) ? actions[selectedSchool?.status as keyof typeof actions] : null}
                {userAdminRoles == -1 || (authStore?.user?.type?.id === 2 || authStore?.user?.type?.id === 3) ?
                    <MenuItem
                        value="delete"

                        sx={{color: 'error.main',}}
                        onClick={handleDelete}
                    >
                        <ListItemIcon sx={{color: 'inherit'}}>
                            <DeleteRounded fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem> : null}

               {/* {userAdminRoles == -1 || (authStore?.user?.type?.id === 2) ? <MenuItem
                    value="view-student"

                    sx={{color: 'primary.dark',}}
                    onClick={handleViewStudent.bind(null, {school: selectedSchool})}
                >
                    <ListItemIcon sx={{color: 'inherit'}}>
                        <VisibilityRounded fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>View Student</ListItemText>
                </MenuItem> : null}*/}


            </MenuList>
        </Menu>


        {schools?.map((school, index) => {


            return (<React.Fragment key={index}>
                <ListItem
                    disablePadding
                    secondaryAction={
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1.5,
                                alignItems: "center",
                            }}
                        >
                            {/* Status Badge */}
                            <Box sx={{ display: "flex", alignItems: "center", color: "secondary.main" }}>
                                {status[school.status as keyof typeof status]}
                            </Box>

                            {/* Action Icons */}
                            {(userAdminRoles === -1 || authStore?.user?.type?.id === 2) && (
                                <Tooltip title="Academic Calendar">
                                    <IconButton
                                        sx={{
                                            color: "text.secondary",
                                            "&:hover": { color: "primary.main" },
                                        }}
                                        onClick={handleViewAcademic.bind(null, {
                                            school: school,
                                        })}
                                        value={school.id}
                                    >
                                        <EventNoteRounded />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {userAdminRoles > -1 && (
                                <Tooltip title="List Students">
                                    <IconButton
                                        sx={{
                                            color: "text.secondary",
                                            "&:hover": { color: "primary.main" },
                                        }}
                                        onClick={handleViewStudent.bind(null, {
                                            school: school,
                                        })}
                                        value={school.id}
                                    >
                                        <VisibilityRounded />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {(authStore?.user?.type?.id === 2 || authStore?.user?.type?.id === 3) && (
                                <IconButton
                                    sx={{
                                        color: "text.secondary",
                                        "&:hover": { color: "primary.main" },
                                    }}
                                    onClick={onRowClick}
                                    value={school.id}
                                >
                                    <MoreHoriz />
                                </IconButton>
                            )}
                        </Box>
                    }
                >
                    <ListItemButton
                        sx={{
                            "&:hover": {
                                backgroundColor: "action.hover",
                            },
                        }}
                    >
                        {/* School Icon */}
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: "primary.main", color: "white" }}>
                                <SchoolRounded />
                            </Avatar>
                        </ListItemAvatar>

                        {/* School Info */}
                        <ListItemText
                            primary={
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    sx={{
                                        color: "text.primary",
                                        cursor: "pointer",
                                        "&:hover": { textDecoration: "underline", color: "primary.main" },
                                    }}
                                    onClick={handleViewSchool.bind(null, {
                                        school: school,
                                        schoolId: school.id,
                                        title: school.name,
                                        region: school.region?.name,
                                        institutionalCode: school.institutionalCode,
                                    })}
                                >
                                    {school.name}
                                </Typography>
                            }
                            secondary={
                                <>
                                    {/* Institutional Code and Address */}
                                    <Box sx={{ mt: 0.5 }}>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{
                                                display: "inline",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {school.institutionalCode ? `Inst. Code: ${school.institutionalCode}` : "No Institutional Code"} |{" "}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{
                                                display: "inline",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {school.address
                                                ? `Address: ${school.address}`
                                                : "No Address Found. Please update your "}
                                            {!school.address && (
                                                <Typography
                                                    variant="body2"
                                                    color="primary"
                                                    component="span"
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        history.push(`/account-settings`);
                                                    }}
                                                >
                                                    School Institutional Profile
                                                </Typography>
                                            )}
                                        </Typography>
                                    </Box>
                                </>
                            }
                        />
                    </ListItemButton>
                </ListItem>
                <Divider />
            </React.Fragment>)
        })}


    </>
}

export default React.memo(SchoolList)
