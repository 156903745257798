import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import {useDebounce} from "../../hooks/useDebounce";
import {useSearchMajorQuery} from "../../hooks/useSearchMajorQuery";
import {MajorType} from "../../types";

interface ISearchAsYouTypeProps {
    label: string
    onSelected: (value: MajorType) => void
    defaultValue?: any
}

const SearchMajorTextField: React.FC<ISearchAsYouTypeProps> = (props) => {
    const {label, onSelected, defaultValue} = props;
    const [name, setName] = React.useState("")
    const debouncedName = useDebounce(name, 500)
    const { data: options , isLoading } = useSearchMajorQuery(debouncedName)
    const [open, setOpen] = React.useState(false);

    return (
        <Autocomplete
            id={"searchMajor"}
            open={open}
            defaultValue={defaultValue}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionLabel={(option) => option?.name}
            options={(options?.data || []) as MajorType[]}

            loading={isLoading}
            onChange={(e, value) => {
               onSelected(value)

            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    margin="dense"
                    size='small'
                    value={name}
                    onChange={(e) => {

                        if(e?.target?.value) setName(e?.target?.value)
                    }}
                    InputProps={{
                        ...params?.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params?.InputProps?.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}


export default React.memo(SearchMajorTextField);
