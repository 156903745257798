import React, { useEffect, useRef, useState } from "react";
import pako from "pako";
import {useSelector} from "react-redux";
import type {RootState} from "../redux/store";
import { decompressMessage } from "../helper/utils/helper";
interface VideoCallProps {
    peerId: string;
}
const VideoCall: React.FC<VideoCallProps> = ({ peerId }) => {
    const authState = useSelector((state: RootState) => state.auth)
    const iceServers = [{ urls: "stun:stun.l.google.com:19302" }];
    const socketRef = useRef<WebSocket | null>(null);
    const peerRef = useRef<RTCPeerConnection | null>(null);
    const localVideoRef = useRef<HTMLVideoElement>(null);
    const remoteVideoRef = useRef<HTMLVideoElement>(null);
    const localStreamRef = useRef<MediaStream | null>(null);
    const [roomId, setRoomId] = useState<string>(""); // Store the current room ID
    const [joined, setJoined] = useState<boolean>(false); // Track if the user has joined a room
    const [spotlightId, setSpotlightId] = useState<string | null>(null);



    useEffect(() => {
        const ws = new WebSocket("wss://localhost:7274/ws");
        socketRef.current = ws;

        ws.onmessage = (event) => {
            try {
                const decompressedMessage = decompressMessage(event.data);
                console.log("Decompressed message:", decompressedMessage);

                const message = JSON.parse(decompressedMessage.trim());
                if (message.roomId !== roomId && peerId !== "") return;

                switch (message.type) {
                    case "spotlight":
                        console.log(`Spotlight changed to participant: ${message.peerId}`);
                        console.log(authState.user.id)
                        setSpotlightId(authState.user.id);
                        break;

                    case "leave-room":
                        console.log("Peer has left the room.");
                        if (remoteVideoRef.current) {
                            remoteVideoRef.current.srcObject = null;
                        }
                        break;
                    case "offer":
                        handleOffer(message.offer);
                        break;
                    case "answer":
                        handleAnswer(message.answer);
                        break;
                    case "ice-candidate":
                        handleIceCandidate(message.candidate);
                        break;
                    default:
                        console.warn("Unhandled message type:", message.type);
                }
            } catch (error) {
                console.error("Error parsing message:", event.data, error);
            }
        };

        return () => {
            ws.close();
        };
    }, [roomId]);
    const changeSpotlight = (peerId: string) => {
        socketRef.current?.send(
            JSON.stringify({
                type: "spotlight",
                roomId,
                peerId,
            })
        );
        setSpotlightId(peerId);
    };
    const joinRoom = () => {
        if (!roomId) {
            alert("Please enter a room ID.");
            return;
        }

        setJoined(true);
        socketRef.current?.send(JSON.stringify({ type: "join-room", roomId }));
    };

    const leaveRoom = () => {
        socketRef.current?.send(JSON.stringify({ type: "leave-room", roomId }));

        if (peerRef.current) {
            peerRef.current.close();
            peerRef.current = null;
        }
        if (localStreamRef.current) {
            localStreamRef.current.getTracks().forEach((track) => track.stop());
            localStreamRef.current = null;
        }

        // Reset local video only
        if (localVideoRef.current) localVideoRef.current.srcObject = null;

        // Keep remote video intact
        console.log("Left room but remote video is preserved.");

        // Reset application state
        setJoined(false);
        setRoomId("");
    };

    const handleOffer = async (offer: RTCSessionDescriptionInit) => {
        const peerConnection = new RTCPeerConnection({ iceServers });
        peerRef.current = peerConnection;

        setupPeerConnection(peerConnection);

        if (!localStreamRef.current) {
            localStreamRef.current = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });
        }

        localStreamRef.current.getTracks().forEach((track) =>
            peerConnection.addTrack(track, localStreamRef.current!)
        );

        await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);

        socketRef.current?.send(JSON.stringify({ type: "answer", answer, roomId }));
    };

    const handleAnswer = async (answer: RTCSessionDescriptionInit) => {
        await peerRef.current?.setRemoteDescription(new RTCSessionDescription(answer));
    };

    const handleIceCandidate = async (candidate: RTCIceCandidateInit) => {
        await peerRef.current?.addIceCandidate(new RTCIceCandidate(candidate));
    };

    const startCall = async () => {
        if (!localStreamRef.current) {
            localStreamRef.current = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });
        }

        if (localVideoRef.current) {
            localVideoRef.current.srcObject = localStreamRef.current;
        }

        const peerConnection = new RTCPeerConnection({ iceServers });
        peerRef.current = peerConnection;

        setupPeerConnection(peerConnection);

        localStreamRef.current.getTracks().forEach((track) =>
            peerConnection.addTrack(track, localStreamRef.current!)
        );

        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        socketRef.current?.send(
            JSON.stringify({ type: "offer", offer, to: peerId, from: authState.user.id })
        );
    };

    const setupPeerConnection = (peerConnection: RTCPeerConnection) => {
        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                socketRef.current?.send(
                    JSON.stringify({
                        type: "ice-candidate",
                        candidate: event.candidate,
                        roomId,
                    })
                );
            }
        };

        peerConnection.ontrack = (event) => {
            if (remoteVideoRef.current && event.streams && event.streams[0]) {
                remoteVideoRef.current.srcObject = event.streams[0];
            } else {
                console.warn("No remote stream available.");
                if (remoteVideoRef.current) {
                    remoteVideoRef.current.srcObject = null;
                }
            }
        };
    };

    return (
        <div style={{ paddingTop: 110 }}>
            <h1>Video Call</h1>
            {!joined ? (
                <div>
                    <input
                        type="text"
                        placeholder="Enter Room ID"
                        value={roomId}
                        onChange={(e) => setRoomId(e.target.value)}
                    />
                    <button onClick={joinRoom}>Join Room</button>
                </div>
            ) : (
                <div>
                    <video ref={localVideoRef} autoPlay muted playsInline></video>
                    <video ref={remoteVideoRef} autoPlay playsInline></video>
                    <button onClick={startCall}>Start Call</button>
                    <button onClick={leaveRoom} style={{marginLeft: "10px"}}>
                        Leave Room
                    </button>
                </div>
            )}
        </div>
    );
};

export default VideoCall;
