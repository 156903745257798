import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { decompressMessage } from "../helper/utils/helper";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";

interface VideoCallProps {
    peerId: string;
    setPeerId: any;
    onClose: () => void;
}


const VideoCall: React.FC<VideoCallProps> = ({ setPeerId, peerId, onClose }) => {
    const authState = useSelector((state: RootState) => state.auth);
    const iceServers = [{ urls: "stun:stun.l.google.com:19302" }];
    const socketRef = useRef<WebSocket | null>(null);
    const peerRef = useRef<RTCPeerConnection | null>(null);
    const localVideoRef = useRef<HTMLVideoElement>(null);
    const remoteVideoRef = useRef<HTMLVideoElement>(null);
    const localStreamRef = useRef<MediaStream | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null); // For ringing sound
    const [isCallInitiate, setIsCallInitiate] = useState(false);
    const [micEnabled, setMicEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);

    const iceCandidateQueue = useRef<RTCIceCandidateInit[]>([]);

    useEffect(() => {

        const ws = new WebSocket("wss://localhost:7274/ws");
        socketRef.current = ws;
        audioRef.current = new Audio("/incoming_call.mp3");

        ws.onopen = () => {
            if (!peerId) return;
            if (audioRef.current) {
                audioRef.current.play().catch((err) => console.error("Audio play error:", err));
            }
            startCall();
        };

        ws.onmessage = async (event) => {
            const decompressedMessage = decompressMessage(event.data);
            const message = JSON.parse(decompressedMessage.trim());
            console.log("Message received:", message.type);
            switch (message.type) {
                case "call-initiate":
                    if (audioRef.current) {
                        audioRef.current.play().catch((err) => console.error("Audio play error:", err));
                    }

                    if (confirm(`Incoming call from user ${message.from}`) === true) {
                        setPeerId(message.from);
                        setIsCallInitiate(true);
                    }
                    break;
                case "offer":
                    if (audioRef.current) {
                        audioRef.current.play().catch((err) => console.error("Audio play error:", err));
                    }
                    await handleOffer(message.offer, message.from);
                    break;
                case "call-ended":
                    onClose();
                    break;
                case "answer":
                    if (audioRef.current) {
                        audioRef.current.pause();
                        audioRef.current.currentTime = 0;
                    }
                    await handleAnswer(message.answer);
                    break;
                case "ice-candidate":
                    await handleIceCandidate(message.candidate);
                    break;
                default:
                    console.warn("Unhandled message:", message);
            }
        };

        return () => {
            cleanupResources();
        };
    }, [peerId]);

    const cleanupResources = () => {
        if (socketRef.current) {
            socketRef.current.close();
        }

        if (localStreamRef.current) {
            localStreamRef.current.getTracks().forEach((track) => track.stop());
            localStreamRef.current = null;
        }

        if (peerRef.current) {
            peerRef.current.close();
            peerRef.current = null;
        }


        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    const startCall = async () => {
        setIsCallInitiate(false);

        if (!localStreamRef.current) {
            localStreamRef.current = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });
        }

        if (localVideoRef.current) {
            localVideoRef.current.srcObject = localStreamRef.current;
        }

        const peerConnection = new RTCPeerConnection({ iceServers });
        peerRef.current = peerConnection;

        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                socketRef.current?.send(
                    JSON.stringify({ type: "ice-candidate", candidate: event.candidate, to: peerId })
                );
            }
        };

        peerConnection.ontrack = (event) => {
            if (remoteVideoRef.current && event.streams[0]) {
                remoteVideoRef.current.srcObject = event.streams[0];
            }
        };

        localStreamRef.current.getTracks().forEach((track) =>
            peerConnection.addTrack(track, localStreamRef.current!)
        );
        console.log(isCallInitiate, "isCallInitiate")
        if (!isCallInitiate) {
            console.log(isCallInitiate, "isCallInitiate")
            socketRef.current?.send(
                JSON.stringify({
                    type: "call-initiate",
                    from: authState.user.id,
                    to: peerId,
                })
            );
        }

        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        socketRef.current?.send(
            JSON.stringify({ type: "offer", offer, to: peerId, from: authState.user.id })
        );
    };

    const handleOffer = async (offer: RTCSessionDescriptionInit, from: string) => {
        const peerConnection = new RTCPeerConnection({ iceServers });
        peerRef.current = peerConnection;

        if (!localStreamRef.current) {
            localStreamRef.current = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });
        }

        localStreamRef.current.getTracks().forEach((track) =>
            peerConnection.addTrack(track, localStreamRef.current!)
        );

        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                socketRef.current?.send(
                    JSON.stringify({ type: "ice-candidate", candidate: event.candidate, to: from })
                );
            }
        };

        peerConnection.ontrack = (event) => {
            if (remoteVideoRef.current && event.streams[0]) {
                remoteVideoRef.current.srcObject = event.streams[0];
            }
        };

        await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
        await processQueuedIceCandidates(); // Process queued ICE candidates
        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);

        socketRef.current?.send(JSON.stringify({ type: "answer", answer, to: from }));

        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    const handleAnswer = async (answer: RTCSessionDescriptionInit) => {
        await peerRef.current?.setRemoteDescription(new RTCSessionDescription(answer));
        await processQueuedIceCandidates();
    };

    const handleIceCandidate = async (candidate: RTCIceCandidateInit) => {
        if (peerRef.current?.remoteDescription) {
            await peerRef.current.addIceCandidate(new RTCIceCandidate(candidate));
        } else {
            iceCandidateQueue.current.push(candidate);
        }
    };

    const processQueuedIceCandidates = async () => {
        while (iceCandidateQueue.current.length > 0) {
            const candidate = iceCandidateQueue.current.shift();
            if (candidate) {
                await peerRef.current?.addIceCandidate(new RTCIceCandidate(candidate));
            }
        }
    };

    const toggleMic = () => {
        if (localStreamRef.current) {
            localStreamRef.current.getAudioTracks().forEach((track) => (track.enabled = !micEnabled));
            setMicEnabled(!micEnabled);
        }
    };

    const toggleVideo = () => {
        if (localStreamRef.current) {
            localStreamRef.current.getVideoTracks().forEach((track) => (track.enabled = !videoEnabled));
            setVideoEnabled(!videoEnabled);
        }
    };

    const sendCallEnded = () => {
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: "call-ended",
                    from: authState.user.id,
                    to: peerId,
                })
            );
        }
    };

    const handleOnClose = () => {
        sendCallEnded();
        cleanupResources();
        onClose();
    };

    return peerId ? (
        <Box
            sx={{
                position: "fixed",
                bottom: 16,
                right: 16,
                width: 300,
                backgroundColor: "white",
                borderRadius: 2,
                boxShadow: 3,
                padding: 2,
                zIndex: 1000,
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Video Call</Typography>
                <IconButton onClick={handleOnClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                <video ref={localVideoRef} autoPlay muted playsInline style={{ width: "100%", borderRadius: 8 }}></video>
                <video ref={remoteVideoRef} autoPlay playsInline style={{ width: "100%", borderRadius: 8 }}></video>
            </Box>
            <Box display="flex" justifyContent="center" gap={2} mt={2}>
                <IconButton onClick={toggleMic} color={micEnabled ? "primary" : "default"}>
                    {micEnabled ? <MicIcon /> : <MicOffIcon />}
                </IconButton>
                <IconButton onClick={toggleVideo} color={videoEnabled ? "primary" : "default"}>
                    {videoEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
                </IconButton>
                <Button variant="contained" color="error" onClick={handleOnClose}>
                    End
                </Button>
            </Box>
        </Box>
    ) : null;
};

export default VideoCall;

